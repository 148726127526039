<template>
  <div class="bg-body mock"></div>
  <div class="bg-body">
    <CourseCompareList/>
  </div>
</template>

<script>

import CourseCompareList from "../components/course_compare/CourseCompareList";
export default {
  name: "CoursesCompare",
  components: {CourseCompareList},
}
</script>

<style scoped>
.mock {
  padding-top: 100px;
}
</style>