import axios from 'axios'
import {
  API_PARTNER,
  API_PROFESSION,
  API_ARTICLE
} from "@/common/config"

export default {
  actions: {
    fetchPartners(context) {
      axios.get(API_PARTNER).then((response) => {
        const partners = response.data
        context.commit('updatePartners', partners)
      })
    },
    fetchProfessions(context) {
      axios.get(API_PROFESSION).then((response) => {
        const professions = response.data
        context.commit('updateProfessions', professions)
      })
    },
    fetchArticles(context) {
      axios.get(API_ARTICLE).then((response) => {
        const articles = response.data.data
        context.commit('updateArticles', articles)
      })
    }
  },
  mutations: {
    updatePartners(state, partners) {
      state.partners = partners
    },
    updateProfessions(state, professions){
      state.professions = professions
    },
    updateArticles(state, articles){
      state.articles = articles
    },
  },
  state: {
    partners: [],
    professions: [],
    articles: []
  },
  getters: {
    allPartners(state) {
      return state.partners
    },
    allProfessions(state) {
      return state.professions
    },
    allArticles(state) {
      return state.articles
    },
    partnerFindById : state => id => {
       return state.partners.find(partners => partners.id === id);
    }
  }
}