<template>
  <vue-collapsible-panel-group accordion>
    <vue-collapsible-panel :expanded="false">
      <template #title>
        Часто задаваемые вопросы
      </template>
      <template #content>
        <div>
          <div class="row question" v-for="(question,index) in questions" :key="index">
            <div class="col-12 col-md-6 d-block d-none d-md-block question_block">
              {{ question.question }}
            </div>
            <div class="col-12 col-md-6 d-none d-md-block">
              <p v-html="question.response"></p>
            </div>


            <!--for mobile-->
            <div class="col-12 col-md-6 accordion-button d-md-none question_block"
                 v-bind:class="{ collapsed: isActive !== index}"
                 @click="toggleItem(index)">
              {{ question.question }}
            </div>
            <div class="col-12 col-md-6 d-md-none" v-if="isActive === index">
              <p class="accordion-body" v-html="question.response"></p>
            </div>
          </div>
        </div>
      </template>
    </vue-collapsible-panel>
  </vue-collapsible-panel-group>
</template>

<script>
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel'

export default {
  name: "FAQ",
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel
  },
  methods: {
    toggleItem(index) {
      this.isActive = this.isActive === index ? null : index;
    },
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      isActive: null
    }
  }
}
</script>

<style scoped lang="scss">
@import 'vue-collapsible.css';

.question {
  .question_block {
    padding: 15px 25px;
  }

  p {
    padding-top: 15px;
    padding-right: 10px;
    font-size: 15px
  }
}

.accordion-button:not(.collapsed) {
  background: white;
  color: var(--deep-gray);
  box-shadow: none;
}
</style>