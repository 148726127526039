<template>
  <div class="card">
    <div class="img-card mb-2">
      <div class="price-card">
        <div class="card-text"><span class="pe-2 fw-bold">{{ price }}</span>
          <span v-if="old_price > 0" class="text-decoration-line-through">
              {{ old_price }} ₽
            </span>
        </div>
        <div v-if="purprice > 0 && duration > 0 && getMonthPrice !== 0" class="card-text">
          <span class="pe-2 fw-bold">{{ getMonthPrice }}</span>
          <span class="fw-bold"> ₽ /мес</span>
        </div>
      </div>
      <img
          :src="pic"
          @error="$event.target.src='https://netology.ru/backend/assets/zaglushka-f55af4c722ba5aaad9741465c1337e0220453875d19278e5d5c96ab86bf7ac0f.png'"
          :alt="name" class="object-fit-cover w-100" loading="lazy">
    </div>
    <div class="bg-white border-0 card-footer d-flex flex-column mb-3">
      <h3 class="d-block mb-2 card-title">{{ name }}</h3>
      <div class="course-spacing">
        <a :href="url" class="btn btn-blue btn-sm-block mb-2" target="_blank"
           @click="incrementCourseStat">
          Пройти курс
        </a>
        <button type="button" :class="{ activeFavorites }" @click="saveInLocalStorage">
          <svg>
            <use xlink:href="#iconFavorites"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <svg style="display:none;">
    <symbol id="iconFavorites" viewBox="0 0 20 20">
      <g>
        <path fill-rule="evenodd"
              d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.178.178 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.178.178 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.178.178 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.178.178 0 0 1-.134-.098L8.16 4.1z"/>
      </g>
    </symbol>
  </svg>
</template>

<script>
import {API_COURSE_STAT} from "../../common/config";
import {useGtag} from "vue-gtag-next";
import moment from "moment";

export default {
  name: "CourseCompareSwiperItem",
  props: {
    id: {
      type: Number,
      required: true,
      default: null
    },
    name: {
      type: String,
      required: true,
      default: null
    },
    is_pro: {
      type: Number,
      required: true,
      default: 0
    },
    duration: {
      type: Number,
      required: false,
      default: null
    },
    old_price: {
      type: String,
      required: false,
      default: '0'
    },
    purprice: {
      type: String,
      required: true,
      default: '0'
    },
    pic: {
      type: String,
      required: true,
      default: null
    },
    url: {
      type: String,
      required: true,
      default: null
    },
  },
  data() {
    return {
      activeFavorites: false,
    };
  },
  computed: {
    durability() {
      return moment.duration(this.$props.duration, "seconds").humanize(false, {M: 1000})
    },
    price() {
      return this.$props.purprice > 0 ? this.formatMoney(this.$props.purprice) + " ₽ " : "Бесплатно "
    },
    getMonthPrice() {
      let months = Math.round(moment.duration(this.$props.duration, "seconds").asMonths())
      return months !== 0 ? Math.floor(this.$props.purprice / months) : 0
    }
  },
  methods: {
    saveInLocalStorage() {
      this.$emit('list')
      if (!this.activeFavorites) this.activeFavorites = true
      else this.activeFavorites = false;
    },
    incrementCourseStat() {
      this.track()
      this.$axios({
        method: 'post',
        url: API_COURSE_STAT,
        data: {
          id: this.course.id
        },
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      })
    },
    track() {
      window.fbq('track', 'ViewContent')
      window.ym(74191996, 'reachGoal', 'click')

      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({"type": "reachGoal", "id": 3226873, "goal": "partner"});

      const {event} = useGtag()
      event('lead', {
        'event_category': 'cat1',
        'event_action': 'click',
        'event_label': 'course'
      })
    },
    formatMoney(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".00", "");
    },
  },

  mounted() {
    const localStorageId = localStorage.getItem('coursesFavouriteIds');

    if (localStorageId) {
      const localStorageArray = JSON.parse(localStorageId);
      for (let id of localStorageArray) {
        if (id === this.id) {
          this.activeFavorites = true
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@mixin imageWrapper($originalWith, $originalHeight) {
  padding-top: $originalHeight / $originalWith * 100%;
  position: relative;
}

.deleteFavorites::v-deep {
  display: none;
}


@media (max-width: 321px) {
  .price-card {
    span {
      font-size: 6px !important;
    }
  }
  .card {
    padding-bottom: 65px !important;
  }
  .course-spacing a {
    width: 82% !important;
  }
}

@media (min-width: 322px) and (max-width: 426px) {
  .course-spacing a {
    width: 85% !important;
  }
}
@media (max-width: 426px){
  .img-card {
    img {
      height: 100px !important;
    }
  }
}
@media (max-width: 770px) {
  .price-card {
    width: 60%;
    padding: 0 !important;

    span {
      font-size: 8px;
      vertical-align: middle;
      padding-right: 2px !important;
    }
  }
  .course-spacing {
    display: contents !important;
  }
  .card-title {
    width: 80%;
    font-size: 15px !important;
  }
  .button-favorites {
    margin-right: 0 !important;
  }
  .course-spacing button {
    bottom: unset !important;
    padding-right: 5px;
  }
}
@media (min-width: 427px) and (max-width: 770px){
  .course-spacing a {
    width: 92% !important;
  }
}

.img-card {
  border-radius: 10px 10px 0 0;
  width: 100%;
  padding-top: 0 !important;
  @include imageWrapper(300, 200);

  .price-card {
    position: absolute;
    color: var(--remotza-slate-blue);
    border: 1px solid var(--remotza-slate-blue);
    background-color: #fff;
    padding: 0.375rem 0.75rem;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
    font-size: 15px;
    border-radius: 5px;
    top: 50%;
    right: 5%;
  }

  img {
    border-radius: 10px 10px 0 0;
    height: 200px;
    object-fit: cover;
  }
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
  padding-bottom: 40px;
}

.course-spacing {
  display: flex;
  justify-content: space-between;

  a {
    margin-left: 13px;
    width: 70%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  button {
    position: absolute;
    border: none;
    padding: 0;
    bottom: 7px;
    margin-right: 10px;
    right: 0;
    background: none;

    svg {
      width: 35px;
      height: 38px;
      stroke: var(--remotza-royal-blue);
      stroke-width: 1px;
      fill: none;
    }
  }
}

:deep .activeFavorites {
  svg {
    fill: var(--remotza-violet-blue) !important;
    stroke: none !important;
  }
}

.card-title {
  font-weight: 500;
  margin-right: 5px;
  font-size: 20px;
  font-style: normal;
  text-align: left;
}

.card-subtitle {
  font-weight: 500;
  font-size: 25px;
  font-style: normal;
  text-align: left;
}

</style>