<template>
  <section class="main-screen">
    <img src="../assets/homepage/decor.png" alt="decor" class="img">
    <Promoblock/>
    <div class="container section-body mt-0" id="for-whom">
      <div class="row">
        <div class="col-12 col-lg-5 col-xl-5 mt-4 mt-md-0 z-index-1">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="it-specialist">Поможем стать востребованным IT-специалистом</h1>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5 col-xl-4 z-index-1 pt-4">
          <p class="remotza-tech">
            потому что мы знаем как, мы сами айтишники —
            <a target="_blank" href="https://remotza.tech">remotza.tech</a>
          </p>
        </div>
        <div class="col-12 col-lg-2 col-xl-3 z-index-1"></div>
      </div>
    </div>
    <div class="container-fluid d-none d-md-block card_block">
      <div class="container">
        <div class="row cards">
          <div class="col-xl-3 col-md-6 col-12 pb-0 pb-md-3">
            <DottedCard :hash="'#test'" :header="'Не знаю с чего начать'">
              <template v-slot:content>
                <ul class="mt-3">
                  <li>Тест на профориентацию</li>
                  <li>FAQ</li>
                </ul>
              </template>
              <template v-slot:button>
                Узнать
              </template>
            </DottedCard>
          </div>
          <div class="col-xl-3 col-md-6 col-12 pb-0 pb-md-3">
            <DottedCard :path="isMobile() ? '/courses' : '/'" :hash="isMobile() ? '' : '#course'"
                        :header="'Выбрал направление, готов учиться'">
              <template v-slot:content>
                <ul class="mt-3">
                  <li>Более 700 обучающих курсов</li>
                  <li>Удобная фильтрация</li>
                  <li>Добавляйте в избранное</li>
                </ul>
              </template>
              <template v-slot:button>
                Выбрать
              </template>
            </DottedCard>
          </div>
          <div class="col-xl-3 col-md-6 col-12 pb-0 pb-md-3">
            <DottedCard :hash="'#mentor-form'" :header="'Нужна помощь наставника'">
              <template v-slot:content>
                <ul class="mt-3">
                  <li>Помощь в устранении пробелов</li>
                  <li>Код ревью</li>
                  <li>Подготовка к собеседованию</li>
                  <li>Помощь при составлении резюме</li>
                </ul>
              </template>
              <template v-slot:button>
                Разобраться
              </template>
            </DottedCard>
          </div>
          <div class="col-xl-3 col-md-6 col-12 pb-0 pb-md-3">
            <DottedCard :hash="'#work-form'" :header="'Кажется, я готов к работе!'">
              <template v-slot:content>
                <ul class="mt-3">
                  <li>Реальное тестовое задание</li>
                  <li>Техническое собеседование</li>
                  <li>Помощь в трудоустройстве</li>
                </ul>
                <p class="fs-7">* требуются рекомендации менторов</p>
              </template>
              <template v-slot:button>
                Работать
                <!--              <RankShield :count="4"/>-->
              </template>
            </DottedCard>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container d-block d-md-none card_block px-0 py-3">
    <div class="container">
      <div class="row cards">
        <div class="col-lg-3 col-md-6 col-12 pb-3">
          <DottedCard :hash="'#test'" :header="'Не знаю с чего начать'">
            <template v-slot:content>
              <ul class="mt-3">
                <li>Тест на профориентацию</li>
                <li>FAQ</li>
              </ul>
            </template>
            <template v-slot:button>
              Узнать
            </template>
          </DottedCard>
        </div>
        <div class="col-lg-3 col-md-6 col-12 pb-3">
          <DottedCard :path="isMobile() ? '/courses' : '/'" :hash="isMobile() ? '' : '#course'"
                      :header="'Выбрал направление, готов учиться'">
            <template v-slot:content>
              <ul class="mt-3">
                <li>Более 700 обучающих курсов</li>
                <li>Удобная фильтрация</li>
                <li>Добавляйте в избранное</li>
              </ul>
            </template>
            <template v-slot:button>
              Выбрать
            </template>
          </DottedCard>
        </div>
        <div class="col-lg-3 col-md-6 col-12 pb-3">
          <DottedCard :hash="'#mentor-form'" :header="'Нужна помощь наставника'">
            <template v-slot:content>
              <ul class="mt-3">
                <li>Помощь в устранении пробелов</li>
                <li>Код ревью</li>
                <li>Подготовка к собеседованию</li>
                <li>Помощь при составлении резюме</li>
              </ul>
            </template>
            <template v-slot:button>
              Разобраться
            </template>
          </DottedCard>
        </div>
        <div class="col-lg-3 col-md-6 col-12 pb-3">
          <DottedCard :hash="'#work-form'" :header="'Кажется, я готов к работе!'">
            <template v-slot:content>
              <ul class="mt-3">
                <li>Реальное тестовое задание</li>
                <li>Техническое собеседование</li>
                <li>Помощь в трудоустройстве</li>
              </ul>
              <p class="fs-7">* требуются рекомендации менторов</p>
            </template>
            <template v-slot:button>
              Работать
            </template>
          </DottedCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DottedCard from "./ui/DottedCard";
import {mapGetters, mapActions} from "vuex";
import Promoblock from "./Promoblock";

export default {
  name: "DriverIt",
  components: {Promoblock, DottedCard},
  computed: mapGetters(['isMobile', 'getCountPromo']),
  methods:mapActions(['fetchCourses', 'fetchPromo']),
  created() {
    this.fetchPromo()
  },
}
</script>

<style lang="scss" scoped>
@import 'src/styles/components/media/media_mainscreen.scss';

.card_block {
  background: var(--pale-gray);
}

.main-screen {
  padding-top: 77px;
  background: var(--remotza-slate-blue);
  overflow: hidden;
  position: relative;

  .card_block {
    padding-bottom: 70px;
    background: linear-gradient(to top, rgb(239, 242, 255) 60%, transparent 40%);
  }

  .img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 78%;
    width: 70%;
  }

  &:after {
    content: url("../assets/logo/remotza_logo_for_mobile.svg");
    position: absolute;
    right: 0;
    top: 80px;
    display: none;
  }

  .it-specialist {
    font-size: 55px;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 70px;
  }

  .remotza-tech {
    font-size: 25px;
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  #for-whom {
    padding-top: 60px;
    padding-bottom: 60px;

    &:before {
      content: url("../assets/logo/remotza_logo_optim.png");
      position: absolute;
      top: 5px;
      right: 0;
    }

  }

  @media (max-width: 576px) {
    .it-specialist {
      font-size: 33px;
      line-height: 38px;
    }

    .remotza-tech {
      font-size: 22px;
    }

    #for-whom {
      padding-bottom: 0;
      padding-top: 0;

      &:before {
        display: none;
      }
    }

    .img {
      display: none !important;
    }
    &:after {
      display: block;
    }
  }

  @media (min-width: 577px) and (max-width: 767.98px) {
    h1 {
      padding-top: 100px;
    }
    .row {
      --gutter-x: 0;
    }
    .cards {
      padding-bottom: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 980.98px) {
    .remotza-tech {
      font-size: 18px;
    }

    .it-specialist {
      font-size: 36px;
      line-height: 40px;
    }

    .img {
      height: 72%;
    }

    #for-whom {
      &:before {
        opacity: 0.5;
      }
    }

    .card_block {
      padding: 0;
    }

  }

  @media (min-width: 1023px) and (max-width: 1440px) {
    .it-specialist {
      font-size: 36px;
      line-height: 50px;
    }

    .img {
      height: 71%;
    }

    #for-whom {
      &:before {
        right: -200px;
      }
    }
  }

  @media (min-width: 2000px) {
    #for-whom {
      &:before {
        right: 15%;
      }
    }
  }
}
</style>