<template>
	<section class="bg-body">
		<div class="container text-start py-5 overflow-hidden">
			<nav>
				<div class="nav nav-tabs">
					<a @click="currentTab = 'TabFront'" class="nav-item prof-tabs-link w-25 text-break " :class="{active: currentTab === 'TabFront'}">Front-end
						<span class="d-none d-md-block"></span>
						<span class="d-none d-md-block">верстка и создание</span>
						<span class="d-none d-md-block">визуальной части сайта</span>
					</a>
					<a @click="currentTab = 'TabBack' " class="nav-item prof-tabs-link w-25 text-break" :class="{active: currentTab === 'TabBack'}"> Back-end
						<span class="d-none d-md-block"></span>
						<span class="d-none d-md-block">настройка процессов, "за</span>
						<span class="d-none d-md-block">кадром", то что происходит</span>
						<span class="d-none d-md-block">на сервере</span>
					</a>
					<a @click="currentTab = 'TabFullstack' " class="nav-item prof-tabs-link w-25 text-break" :class="{active: currentTab === 'TabFullstack'}"> Fullstack
						<span class="d-none d-md-block py-2"></span>
						<span class="d-none d-md-block">универсальный специалист</span>
						<span class="d-none d-md-block">полного цикла</span>
						<span class="d-none d-md-block">программирования</span>
					</a>
					<a @click="currentTab = 'TabQa' " class="nav-item prof-tabs-link w-25 text-break" :class="{active: currentTab === 'TabQa' }"> Quality Control
						<span class="d-none d-md-block py-2"></span>
						<span class="d-none d-md-block">верстка и создание</span>
						<span class="d-none d-md-block">визуальной части сайта</span>
					</a>
				</div>
			</nav>
			<component v-bind:is="currentTab" class="tab"></component>
		</div>
	</section>
</template>

<script>
import TabFront
  from "./tabs/TabFront.vue";
import TabBack
  from "./tabs/TabBack.vue";
import TabFullstack
  from "./tabs/TabFullstack.vue";
import TabQa
  from "./tabs/TabQa.vue";

export default {
  name: 'ProfessionTabs',
  components: {
    TabFront,
    TabBack,
    TabFullstack,
    TabQa
  },
  data() {
    return {
      currentTab: 'TabFront',
    }
  },
  created() {
    this.currentTab = this.$route.params.tab || 'TabFront'
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/components/media/media_profession_tabs";

.prof-tabs-link {
  border: 1px solid #80808030;
  border-bottom: 4px solid transparent;
  color: var(--dark);
  font-size: 30px;
  display: block;
  padding: 25px 20px;
  writing-mode: initial;
  cursor: pointer;

  &:hover {
    border-color: var(--remotza-violet-blue);
    text-decoration: none;
    color: var(--dark);
  }

  span {
    color: var(--dark-grey);
    font-size: 16px;
  }
}

.prof-tabs-link.active, .nav-tabs .nav-item.show .prof-tabs-link {
  color: var(--dark);
  border-bottom-color: var(--remotza-slate-blue);
}
</style>
