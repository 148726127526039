<template>
  <div id="course" class="anchor visually-hidden position-relative"></div>
  <section class="col bg-body pb-5">
    <div class="custom-spinner pacman-center my-3 d-flex" style="margin-left: 50%">
      <pacman-loader :loading="getLoadingStatus" :color="'#4970FF'"></pacman-loader>
    </div>
    <div class="container" v-if="!getLoadingStatus">
      <div class="d-flex flex-row justify-content-between flex-lg-row">
        <h2 class="mb-lg-0 text-right text-violet-dark">Курсы и тренинги {{ item }}</h2>
        <form class="d-block">
          <CourseSort />
        </form>
      </div>
      <div class="row mt-lg-5 mt-3">
        <div class="col">
          <div id="course-list" v-if="getCourses.length > 0" :class="{'row row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3': getCourses.length > 4, 'row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-2': getCourses.length <= 4}">
            <CourseItem v-for="(course, index) in getCourses" :key="course.id"
                        :id="course.id"
                        :name="course.name"
                        :school="course.partner?.name"
                        :is_pro="course.is_pro"
                        :purprice="course.purprice"
                        :old_price="course.old_price"
                        :duration="course.duration"
                        :pic="course.pic"
                        :url="course.url"
                        :technologies="course.proglangs"
                        :page_context="course.page_context"
                        @update="toggleShowModal"
                        @setCourse="setCurrentCourse(index)"
                        @ids="addToCompare(course.id)"
                        @list="listId(course.id)"
            />
          </div>
          <div class="empty" v-else>
            <h3 class="alert fs-1 text-center w-100">Ничего не найдено</h3>
          </div>
          <CourseModal v-if="isShow" :course="currentCourse" :is-show="isShow" @update="toggleShowModal"></CourseModal>
          <div class="d-flex">
            <div v-if="!getLoadingStatus && getCourses.length == 0" id="empty-message" class="alert fs-5 text-center w-100"
                 role="alert">
              <h3 style="color: black">Ничего не найдено</h3>
            </div>
            <slot v-if="!getLoadingStatus && !getCourses.length == 0" name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CourseItem from "@/components/course/CourseItem"
import CourseModal from "@/components/course/CourseModal"
import CourseSort from "@/components/course/CourseSort"
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue'
import {
  mapGetters,
  mapMutations,
} from "vuex"

export default {
  name: "CourseList",
  components: {
    CourseModal,
    CourseItem,
    PacmanLoader,
    CourseSort
  },
  computed: {
    ...mapGetters(['getCourses', 'courseLength', 'getLoadingStatus', 'getLastCourseLength', 'getCoursesCompareCount', 'getCoursesCompareIds']),
  },
  data() {
    return {
      favoritesId:[],
      isShow: false,
      currentCourse: null,
      params_filter: null,
      counter: 0
    }
  },
  methods: {
    ...mapMutations(['updatePage', 'updateLimit', 'updateLoadingStatus', 'removeCoursesCompareId', 'updateLocalStorageId']),
    toggleShowModal() {
      this.isShow = !this.isShow
      if (!this.isShow) {
        this.currentCourse = null
        document.body.classList.remove('modal-open')
      } else {
        document.body.classList.add('modal-open');
      }
    },
    addToCompare(id) {
      if (localStorage.getItem('coursesCompareIds') == null) {
        localStorage.setItem('coursesCompareIds', JSON.stringify([]))
      }

      if (JSON.parse(localStorage.getItem('coursesCompareIds')).indexOf(id) >= 0) {
        this.removeCoursesCompareId(id)
      } else {
        this.$store.commit('addCoursesCompareId', id)
      }
      this.emitter.emit("compareCount");
    },
    setCurrentCourse(id) {
      this.currentCourse = this.getCourses[id]
    },
    listId(id) {
      let index = this.favoritesId.indexOf(id)
      if (index >= 0) {
        this.favoritesId.splice(index, 1)
      } else this.favoritesId.push(id)
      this.updateLocalStorageId(this.favoritesId)
      localStorage.setItem('coursesFavouriteIds', JSON.stringify(this.favoritesId))
      this.emitter.emit("FavoritesCount");
    },
  },
  mounted() {
    const localStorageId = localStorage.getItem('coursesFavouriteIds');
    if (localStorageId){
      const localStorageArray = JSON.parse(localStorageId);
      this.favoritesId = localStorageArray.map(id => +id);
    }
  }
}
</script>

<style>
.pacman-center {
  width: 100px;
  margin: auto;
}
.empty {
   color: var(--remotza-royal-blue);
 }
</style>