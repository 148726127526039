<template>
  <div id="work-form" class="anchor visually-hidden position-relative"></div>
  <section
      class="work__form">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6">
          <h2 class="text-work">
            Мы можем помочь тебе с поиском работы, для этого необходима информация о твоих навыках и полученных знаниях
          </h2>
          <p v-show="!isSuccess" class="pt-3 pt-xl-5 fill_form">Заполни небольшую форму и мы
            обязательно с тобой свяжемся</p>
        </div>
        <div class="col-12 col-xl-6 mt-0 mt-md-5 mt-lg-0">
          <Form v-show="!isSuccess"
                :validation-schema="schema"
                class="row gx-0"
                @submit="onSubmit"
                method="post"
                v-slot="{ errors }"
          >
            <div class="col-lg-6 p-2">
              <Field v-model="name"
                     :class="{ 'is-invalid': errors.name }"
                     name="name"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Имя"
                     required
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            <div class="col-lg-6 p-2">
              <Field v-model.trim="email"
                     :class="{ 'is-invalid': errors.email }"
                     name="email"
                     type="text"
                     class="form-control input-blue"
                     placeholder="E-mail"
                     required
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="col-lg-6 p-2">
              <Field v-model="tech"
                     :class="{ 'is-invalid': errors.tech }"
                     name="tech"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Стек технологий"
                     required
              />
              <div class="invalid-feedback">{{ errors.tech }}</div>
            </div>
            <div class="col-lg-6 p-2">
              <Field v-model="courses"
                     :class="{ 'is-invalid': errors.courses }"
                     name="courses"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Пройденные курсы"
                     required
              />
              <div class="invalid-feedback">{{ errors.courses }}</div>
            </div>
            <div class="col-lg-6 p-2">
              <Field v-model="mentor"
                     :class="{ 'is-invalid': errors.mentor }"
                     name="mentor"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Контакты ментора"
                     required
              />
              <div class="invalid-feedback">{{ errors.mentor }}</div>
            </div>
            <div class="col-lg-6 p-2">
              <Field v-model="comment"
                     name="comment"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Коментарий"
                     required
              />
            </div>
            <div class="col p-2">
              <button ref="button_type_submit" type="submit" :class="{ disabled: isDisable }"
                      class="btn btn-blue w-100">
                <span v-if="spin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <div :class="{ 'd-none': spin }">Отправить</div>
              </button>
              <p class="privacy-policy">
                Нажимая на кнопку «отправить», я соглашаюсь на обработку персональных данных и с
                <a @click="isShow=!isShow"
                   class="link-dark-grey mt-3 mt-md-auto privacy-policy text-decoration-underline">политикой
                  конфиденциальности</a>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
  <ModalText :isShow="isShow" :modalText="modalText" @close="isShow = !isShow">
    <PrivacyPolicy></PrivacyPolicy>
  </ModalText>
</template>

<script>
import ModalText from "../modal/ModalText"
import PrivacyPolicy from "../html_templates/PrivacyPolicy"
import {Form, Field} from 'vee-validate'
import * as Yup from "yup"
import {API_PERSON_INFO} from "../../common/config"
import {useGtag} from "vue-gtag-next"

export default {
  name: "WorkForm",
  components: {
    Form,
    Field,
    ModalText,
    PrivacyPolicy
  },
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
      tech: Yup.string().required('Обязательное поле'),
      courses: Yup.string().required('Обязательное поле'),
      mentor: Yup.string().required('Обязательное поле'),
      email: Yup.string().required('Обязательное поле').email('Некорректный email'),
    })

    return {
      schema
    }
  },
  data() {
    return {
      email: '',
      name: '',
      tech: '',
      courses: '',
      mentor: '',
      comment: null,
      isSuccess: false,
      isDisable: false,
      spin: false,
      isShow: false
    }
  },
  methods: {
    onSubmit(values, { resetForm }) {
      this.dataToggler(true);

      let formData = {
        email: values.email,
        name: values.name,
        tech: values.tech,
        mentor: values.mentor,
        courses: values.courses,
        comment: values.comment,
      }

      this.$axios({
        method: 'post',
        url: API_PERSON_INFO,
        data: formData,
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      }).then(() => {
        this.$notify({
          title: "Ваша заявка учтена!",
          text: "Мы с вами свяжемся",
          closeOnClick: true,
          type: 'success',
        });
        resetForm()
        this.dataToggler(false)

        window.fbq('track', 'Lead')
        window.ym(74191996, 'reachGoal', 'wannawork')
        const {event} = useGtag()
        event('lead', {
          'event_category': 'cat1',
          'event_action': 'wannawork'
        })
      }).catch((error) => {
        this.$notify({
          title: 'Ошибка',
          text: error.response.data.data[0].message,
          closeOnClick: true,
          type: 'error',
        });
        this.dataToggler(false);
      })

    },
    dataToggler(boolean) {
      this.isDisable = boolean
      this.spin = boolean
    },
  }
}
</script>

<style lang="scss" scoped>
.work__form {
  background: linear-gradient(180deg, rgba(239, 242, 255, 0.5) 0%, var(--pale-gray) 100%);
  padding: 103px 0;

  form {
    border: 1px solid lightgray;
    padding: 30px 50px 50px 50px;
    border-radius: 5px;

    .privacy-policy {
      color: var(--smoky);
      opacity: 0.7;
      font-size: 12px;
      line-height: 18px;
      margin-top: 20px;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .text-work {
    color: var(--deep-gray);
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    padding-right: 120px;
  }

  .fill_form {
    color: var(--deep-gray);
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    padding-right: 120px;
  }

  input {
    background: #F3F3FD;
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--smoky);
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: var(--smoky);
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--smoky);
  }

  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: var(--smoky);
  }
}

@media (max-width: 1199.98px) {
  .work__form {
    padding: 50px 0;

    .text-work {
      font-size: 20px;
      line-height: 30px;
      padding-right: 0;
    }

    .fill_form {
      font-size: 16px;
      line-height: 25px;
      padding-right: 0;
    }

    form {
      padding: 15px;
    }
  }
}

</style>