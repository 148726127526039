<template>
  <div id="mentor-form" class="anchor visually-hidden position-relative"></div>
  <section class="mentor__form">
    <img src="../../assets/homepage/decor.png" alt="decor" class="img">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6">
          <h2 class="mt-lg-0 text-white text-left">
            Кому нужен опытный наставник?
          </h2>
          <div class="mt-lg-0 text-left pt-5">
            <ul>
              <li>Ты только прошел курсы и хочешь работать, но работодатели ищут специалиста с опытом. Найди себе
                наставника, заполнив небольшую форму и все получится!
              </li>
              <li>Тебе нужно, чтобы опытный разработчик сделал код-ревью твоего кода</li>
              <li>Ты взял проект на фрилансе, но переоценил свои силы, и у тебя возникли сложности</li>
              <li>Тебе нужна помощь в подготовке к онлайн-собеседованию</li>
              <li>Вы - команда начинающих программистов (стартап), ищете опытного наставника тимлида</li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-xl-6 mt-0 mt-md-5 mt-xl-0">
          <Form class="row gx-0"
                :validation-schema="schema"
                @submit="onSubmit"
                method="post"
                v-slot="{ errors }"
          >
            <div class="col-md-6 p-2">
              <Field v-model="name"
                     :class="{ 'is-invalid': errors.name }"
                     name="name"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Имя"
                     required
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            <div class="col-md-6 p-2">
              <Field v-model.trim="email"
                     :class="{ 'is-invalid': errors.email }"
                     name="email"
                     type="text"
                     class="form-control input-blue"
                     placeholder="E-mail"
                     required
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>
            <div class="col-md-6 p-2">
              <Field v-model="tech"
                     :class="{ 'is-invalid': errors.tech }"
                     name="tech"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Стек технологий"
                     required
              />
              <div class="invalid-feedback">{{ errors.tech }}</div>
            </div>
            <div class="col-md-6 p-2">
              <Field v-model="comment"
                     name="comment"
                     type="text"
                     class="form-control input-blue"
                     placeholder="Комментарий"
              />
            </div>
            <div class="col p-2">
              <button ref="button_type_submit" type="submit" :class="{ disabled: isDisable }"
                      class="btn btn-blue w-100">
                <span v-if="spin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <div :class="{ 'd-none': spin }">Отправить</div>
              </button>
              <p class="privacy-policy">
                Нажимая на кнопку «отправить», я соглашаюсь на обработку персональных данных и с
                <a @click="isShow=!isShow"
                   class="link-dark-grey mt-3 mt-md-auto privacy-policy text-decoration-underline">политикой
                  конфиденциальности</a>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
  <ModalText :isShow="isShow" :modalText="modalText" @close="isShow = !isShow">
    <PrivacyPolicy></PrivacyPolicy>
  </ModalText>
</template>

<script>
import {Form, Field} from 'vee-validate'
import {API_FEEDBACK} from "../../common/config";
import * as Yup from "yup";
import {useGtag} from "vue-gtag-next"
import ModalText from "../modal/ModalText"
import PrivacyPolicy from "../html_templates/PrivacyPolicy"

export default {
  name: "MentorForm",
  components: {
    Form,
    Field,
    ModalText,
    PrivacyPolicy
  },
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
      tech: Yup.string().required('Обязательное поле'),
      email: Yup.string().required('Обязательное поле').email('Некорректный email'),
    })

    return {
      schema
    }
  },
  data() {
    return {
      email: '',
      name: '',
      tech: '',
      comment: null,
      isDisable: false,
      spin: false,
      isError: false,
      isShow: false
    }
  },
  methods: {
    onSubmit(values, { resetForm }) {
      this.dataToggler(true);

      let formData = {
        email: values.email,
        name: values.name,
        tech: values.tech,
        type: "learner",
        comment: values.comment,
      }
      this.$axios({
        method: 'post',
        url: API_FEEDBACK,
        data: formData,
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      }).then(() => {
        this.$notify({
          title: "Ваша заявка учтена!",
          text: "Мы с вами свяжемся",
          closeOnClick: true,
          type: 'success',
        });
        this.dataToggler(false)
        resetForm()

        window.fbq('track', 'Lead')
        window.ym(74191996, 'reachGoal', 'need_mentor')
        const {event} = useGtag()
        event('lead', {
          'event_category': 'cat1',
          'event_action': 'mentor'
        })
      }).catch((error) => {
        this.$notify({
          title: 'Ошибка',
          text: error.response.data.data[0].message,
          closeOnClick: true,
          type: 'error',
        });
        //this.errors = error.response.data.data
        this.dataToggler(false);
      })
    },
    dataToggler(boolean) {
      this.isDisable = boolean
      this.spin = boolean
    },
  },
}
</script>

<style lang="scss" scoped>
.mentor__form {
  position: relative;
  overflow-x: clip;
  background: var(--remotza-slate-blue);
  padding: 103px 0;

  .img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 70%;
  }

  .row {
    h2 {
      position: relative;
    }

    ul {
      padding-right: 80px;

      li {
        opacity: 0.7;
        margin-bottom: 20px;
        line-height: 20px;
      }
    }

    form {
      position: relative;
      border: 1px solid var(--remotza-royal-blue);
      padding: 30px 50px 50px 50px;
      border-radius: 5px;
      background: rgba(56, 56, 198, 0.8);
      z-index: 2;

      .privacy-policy {
        color: white;
        opacity: 0.7;
        font-size: 12px;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &:before {
    content: url(../../assets/svg/cubes_logos_decor.svg);
    width: 1250px;
    position: absolute;
    display: block;
    left: 50%;
    top: -65px;
    height: auto;
    max-width: 100%;
    z-index: 1;
  }

  input {
    background: #3A3AC1;
    border-color: var(--remotza-royal-blue);
    color: white;
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    color: #87A0FF;
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #87A0FF
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #87A0FF
  }

  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #87A0FF
  }
}

@media (max-width: 1199.98px) {
  .mentor__form {
    padding: 50px 0;

    &:before {
      display: none;
    }

    .row {
      ul {
        padding-right: 0;
      }

      form {
        padding: 15px;
      }
    }

  }
}
</style>