<template>
  <div class="dropdown" v-if="checkRoute">
      <button :class="{'nav-link dropdown-toggle text-blue bg-body': !isVisible, 'nav-link dropdown-toggle text-blue bg-body show': isVisible}" data-bs-auto-close="true" @click.self="isVisible = !isVisible" type="button" id="dropdownMenuButton1"
              :aria-expanded="isVisible">
        Сортировка
      </button>
      <ul ref="list" :class="{'dropdown-menu border-open bg-body show': isVisible, 'dropdown-menu border-open bg-body': !isVisible}" @mouseleave="isVisible = false" aria-labelledby="dropdownMenuSort">
        <li v-for="sort in sortTypes" :key="sort.sortType"><a class="dropdown-item" href="#" @click="addFilterSort(sort.sortType)">{{ sort.sortName }}</a></li>
      </ul>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "CourseSort",
  computed: {
    ...mapGetters(
        [
          'getLoadingStatus',
          'getSort',
        ]
    ),
    checkRoute() {
      return this.$route.path == '/courses' ? true : false
    },
  },
  data() {
    return {
      isVisible: false,
      sortTypes: [
        { sortType: 'default', sortName: 'по умолчанию'},
        { sortType: 'purprice', sortName: 'по увеличению цены'},
        { sortType: '-purprice', sortName: 'по уменьшению цены'},
        // { sortType: '-id', sortName: 'по новизне'},
        // { sortType: 'duration', sortName: 'по длительности'},
        { sortType: '-popular', sortName: 'по популярности'},
      ]
    }
  },
  methods: {
    ...mapGetters(
        [
          'getFilterParams',
        ]
    ),
    ...mapMutations(
        [
          'clearFilterSort',
          'updateFilterSort',
          'updateLoadingStatus',
        ]
    ),
    addFilterSort(sort) {
      this.updateLoadingStatus(true)
      this.updateFilterSort(sort)
      this.$store.dispatch('applyFilter')
      this.$router.push({query: this.getFilterParams()})
    },
    hideNav() {
      window.onclick = (event) =>{
        if (!event.target.matches('.show')) {
          this.isVisible = false
        }
      }
    }
  },
  mounted() {
    if(this.checkRoute) this.hideNav()
  },
  beforeUnmount() {
    window.onclick = '';
  }
}
</script>

<style scoped>
.nav-link {
  font-size: 16px;
  padding-bottom: 6px;
  padding-top: 6px;
  border: 1px solid transparent;
}

.dropdown-menu .dropdown-item:hover {
  background: var(--remotza-slate-blue);
  color: white;
}

.dropdown .nav-link {
  border: 1px solid transparent;
}

.nav-link.show {
  position: relative;
  z-index: 10;
  background: white;
  border: 1px solid var(--remotza-slate-blue);
  /*border-right: 1px solid var(--remotza-slate-blue);*/
  /*border-top: 1px solid var(--remotza-slate-blue);*/
  border-radius: 0;
}

@media (max-width: 1024px) {
  .dropdown-menu {
    position: absolute;
    right: 0;
  }
}

.dropdown-menu {
  border: 1px solid var(--remotza-slate-blue);
  margin-top: 5px !important;
  z-index: 1;
  border-radius: 0;
}

</style>
