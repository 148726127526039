<template>
  <div class="container promo p-0">
    <Carousel v-if="getCountPromo > 0" :settings="settings" :breakpoints="breakpoints" class="z-index-1">
      <Slide v-for="promo in getAllPromo" :key="promo.id">
        <a :href=" promo.url">
          <div class="carousel__item">
            <img :src="promo.pic" :alt="promo.name" class="carousel__image" loading="lazy">
            <div class="carousel__name">{{ promo.name }}</div>
          </div>
        </a>
      </Slide>
      <template #addons>
        <Navigation v-if="isShowNavigation"/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Carousel, Navigation, Slide} from 'vue3-carousel';

import '@/styles/components/carousel/carousel_promo.css';
import { mapGetters} from "vuex";

export default defineComponent({
  name: 'Promoblock',
  computed: {
    ...mapGetters(['getAllPromo', 'getCountPromo', 'isMobile']),
    isShowNavigation() {
      let isShow = false
      if (this.isMobile() && this.getCountPromo > 1) isShow = true
      if (!this.isMobile() && this.getCountPromo > 4) isShow = true
      return isShow
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    breakpoints: {  
      765: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      800: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      991: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      1199: {
        itemsToShow: 4,
        snapAlign: 'center',
      },
    },
  }),
});
</script>

<style scoped>
.promo {
  height: 200px;
}
</style>