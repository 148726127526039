import axios from 'axios';
import authService from "./auth.service";
import router from '../router';

const httpClient = axios.create();

const authInterceptor = config => {
    config.headers.Authorization = `Bearer ${authService.getToken()}`;
    return config;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401){
            router.push({name: 'login'})
        }
        return Promise.reject(error);
    }
);

export default httpClient;
