import {createStore} from 'vuex'
import filter from "../store/modules/filter"
import window from "../store/modules/window"
import common from "../store/modules/common"
import courseSimilar from "../store/modules/courseSimilar"
import promo from "../store/modules/promo"
import proglangs from "../store/modules/proglangs"
import coursesCompare from "./modules/coursesCompare";

export default createStore({
    modules: {
        courseSimilar,
        filter,
        window,
        common,
        promo,
        proglangs,
        coursesCompare
    },
})
