import axios from "axios"
import {API_COURSE} from "@/common/config"
import {API_COURSES_COMPARE} from "../../common/config";

export default {
  actions: {
    fetchCourses({commit, getters}, params = {}) {
      params = getters.getFilterParams;
      axios.get(API_COURSE, {params}).then((response) => {
        const courses = response.data
        commit('updateCourses', courses)
        commit('updateLoadingStatus', false)
      })
    },
    fetchFavorites({commit, getters}, params = {}) {
      params = getters.getLocalStorageId
      axios.get(API_COURSES_COMPARE, {params}).then((response) => {
        const favorites = response.data
        commit('updateFavorites', favorites)
        commit('updateLoadingStatus', false)
      })
    },
    deleteFavorites({commit},index){
      commit('removeFavorites', index)
    },
    applyFilter({commit, getters}, params = {}){
      params =  getters.getFilterParams;
      axios.get(API_COURSE, {params}).then((response) => {
        const courses = response.data
        commit('applyFilterCourse', courses)
        commit('updateCourses', courses)
        commit('updateLoadingStatus', false)
      })
    },
    searchCourses({commit, getters}, params = {}){
      params = getters.getFilterParams;
      axios.get(API_COURSE, {params}).then((response) => {
        const courses = response.data
        commit('searchCourses', courses.slice(0, 5))
        commit('updateLoadingStatus', false)
      })
    },
  },
  mutations: {
    updateCourses(state, courses) {
      state.last_course_length = courses.length
      state.courses = Array.from(new Set(courses.concat(courses)))
    },
    updateFavorites(state, favorites){
      state.favorites = state.favorites.concat(favorites)
    },
    removeFavorites(state,index){
      state.favorites.splice(index, 1)
    },
    clearStateCourses(state){
      state.courses = [];
    },
    clearStateFavorites(state){
      state.favorites = [];
    },
    applyFilterCourse(state, courses){
      state.courses = courses;
    },
    searchCourses(state, courses){
      state.search_courses = courses;
    },
    clearSearchCourses(state) {
      state.search_courses = [];
    },
    updatePage(state) {
      state.filterParams.page++;
    },
    clearPage(state){
      state.filterParams.page = 0;
    },
    updateFilterProfessions(state, filter_professions) {
      state.filterParams.filter_professions = filter_professions
    },
    clearFilterProfessions(state){
      state.filterParams.filter_professions = [];
    },
    updateLocalStorageId(state,localStorageId){
      state.localStorageId.ids = localStorageId
    },
    updateFilterSchools(state, filter_school) {
      state.filterParams.filter_school = filter_school
    },
    updateFilterLevel(state, filter_level) {
      state.filterParams.filter_level = filter_level
    },
    updateFilterTime(state, filter_time) {
      state.filterParams.filter_time = filter_time
    },
    updateFilterPriceMin(state, filter_min) {
      state.filterParams.filter_min = filter_min
    },
    updateFilterPriceMax(state, filter_max) {
      state.filterParams.filter_max = filter_max
    },
    updateFilterProglang(state, proglang_id) {
      state.filterParams.filter_proglang = proglang_id
    },
    updateFilterPattern(state, filter_pattern) {
      if (filter_pattern == '') state.filterParams.filter_pattern = null
      else state.filterParams.filter_pattern = filter_pattern
    },
    clearFilterPattern(state) {
      state.filterParams.filter_pattern = ''
    },
    allClearFilter(state){
      state.filterParams.filter_professions =[]
      state.filterParams.filter_school = []
      state.filterParams.filter_level = []
      state.filterParams.filter_time = []
      state.filterParams.filter_min = 0
      state.filterParams.filter_max = 1300000
      state.filterParams.filter_proglang = null
      state.filterParams.filter_pattern = null
      state.filterParams.sort = null
    },
    updateLoadingStatus(state, newLoadingStatus) {
      state.loadingStatus = newLoadingStatus
    },
    updateLimit(state,limit) {
      state.filterParams.limit = limit
    },
    clearFilterSort(state){
      state.filterParams.sort = null;
    },
    updateFilterSort(state,sort) {
      state.filterParams.sort = sort
    }
  },
  state: {
    courses: [],
    favorites: [],
    search_courses: [],
    last_course_length: 0,
    localStorageId:{
      ids: [],
    },
    filterParams: {
      page: 0,
      sort: null,
      filter_professions: [],
      filter_school: [],
      filter_level: [],
      filter_time: [],
      filter_min: 0,
      filter_max: 1300000,
      filter_proglang: null,
      filter_pattern: null,
      limit: null
    },
    loadingStatus: true
  },
  getters: {
    getCourses(state) {
      return state.courses
    },
    getFavorites(state){
      return state.favorites
    },
    getSort(state) {
      return state.filterParams.sort
    },
    getLocalStorageId(state){
      return state.localStorageId
    },
    getSearchCourses(state) {
      return state.search_courses
    },
    getLastCourseLength(state) {
      return state.last_course_length
    },
    getFilterParams(state){
      return state.filterParams
    },
    courseLength(state) {
      return state.courses.length
    },
    getPage(state) {
      return state.filterParams.page
    },
    getFilterProfessions(state){
      return state.filterParams.filter_professions
    },
    getFilterMax(state){
      return state.filterParams.filter_max
    },
    getFilterMin(state){
      return state.filterParams.filter_min
    },
    getFilterPattern(state) {
      return state.filterParams.filter_pattern
    },
    getFilterProglang(state) {
      return state.filterParams.filter_proglang
    },
    getLoadingStatus(state) {
      return state.loadingStatus
    },
    getFilterTime(state) {
      return state.filterParams.filter_time
    },
    getFilterSchool(state) {
      return state.filterParams.filter_school
    },
    getFilterLevel(state) {
      return state.filterParams.filter_level
    },
    getLimit(state) {
      return state.filterParams.limit
    },
  }
}