<template>
  <div id="app">
    <a class="back_to_top" ref="back_to_top" @click="scrollToTop()">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-up"
           viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
      </svg>
    </a>
  </div>

</template>

<script>
export default {
  name: "ScrollButton",

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.pageYOffset > 200) {
        this.$refs.back_to_top.classList.add('show');
      } else if (window.pageYOffset < 200) {
        this.$refs.back_to_top.classList.remove('show')
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },


}

</script>

<style lang="scss" scoped>

.back_to_top {
  position: fixed;
  bottom: 60px;
  right: 35px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 30px;
  background: var(--white);
  color: var(--remotza-violet-blue);
  cursor: pointer;
  border: 3px solid var(--remotza-violet-blue);
  border-radius: 2px;
  display: none;
}

.back_to_top:hover {
  background: var(--pale-gray);
}

:deep(.show) {
  display: block;
}
</style>