<template>
  <div class="bg-body mock"></div>
  <section class="bg-body py-5">
    <div class="container">
      <div class="d-flex flex-column flex-lg-row">
        <h2 class="mb-3 text-violet-dark">Избранное</h2>
      </div>
      <div class="row mt-lg-5 mt-3" v-if="favoritesId.length > 0 && !getLoadingStatus">
        <div class="col">
          <div id="course-list" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
            <FavoritesItem v-for="(course, index) in getFavorites" :key="course.id"
                           :id="course.id"
                           :name="course.name"
                           :school="course.partner?.name"
                           :is_pro="course.is_pro"
                           :purprice="course.purprice"
                           :old_price="course.old_price"
                           :duration="course.duration"
                           :pic="course.pic"
                           :url="course.url"
                           :technologies="course.proglangs"
                           @update="toggleShowModal"
                           @setCourse="setCurrentCourse(index)"
                           @deleteFromFavorites="deleteFromFavorites(course.id, index)"
            />
          </div>
          <CourseModal v-if="isShow" :course="currentCourse" :is-show="isShow" @update="toggleShowModal"></CourseModal>
        </div>
      </div>
      <div class="custom-spinner pacman-center my-3" v-if="favoritesId.length > 0">
        <pacman-loader :loading="getLoadingStatus" :color="'#4970FF'"></pacman-loader>
      </div>
      <div v-else>
        <p class="text-dark-grey">В избранном нет курсов</p>
      </div>
    </div>
  </section>
</template>

<script>
import CourseModal from "@/components/course/CourseModal"
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue'
import FavoritesItem from "../components/FavoritesItem"

import {
  mapActions,
  mapGetters,
  mapMutations,
} from "vuex"

export default {
  name: "CourseList",
  components: {
    CourseModal,
    PacmanLoader,
    FavoritesItem
  },
  computed: mapGetters(['getFavorites', 'getLocalStorageId', 'getCourses', 'test', 'getLoadingStatus', 'getLastCourseLength']),
  data() {
    return {
      favoritesId: [],
      isShow: false,
      currentCourse: null,
      params_filter: null
    }
  },
  methods: {
    ...mapMutations(['updatePage', 'updateLimit', 'updateLocalStorageId', 'clearStateFavorites']),
    ...mapActions(['fetchFavorites', 'deleteFavorites']),
    toggleShowModal() {
      this.isShow = !this.isShow
      if (!this.isShow) {
        this.currentCourse = null
        document.body.classList.remove('modal-open')
      } else {
        document.body.classList.add('modal-open');
      }
    },
    setCurrentCourse(id) {
      this.currentCourse = this.getFavorites[id]
    },
    deleteFromFavorites(id, index) {
      let favoritesIndex = this.favoritesId.indexOf(id)
      this.favoritesId.splice(favoritesIndex, 1)
      this.updateLocalStorageId(this.favoritesId)
      localStorage.setItem('coursesFavouriteIds', JSON.stringify(this.favoritesId))
      this.deleteFavorites(index)
    }
  },
  mounted() {
    const localStorageId = localStorage.getItem('coursesFavouriteIds');
    if (localStorageId) {
      const localStorageArray = JSON.parse(localStorageId);
      this.favoritesId = localStorageArray.map(id => +id);
    }
    this.updateLocalStorageId(this.favoritesId)
    this.clearStateFavorites()
    this.fetchFavorites()
  }
}
</script>

<style scoped>
.mock {
  padding-top: 100px;
}
</style>