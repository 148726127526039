<template>
  <div class="container">
    <div class="search-block pt-4">
      <div class="input-group">
        <input
            class="form-control"
            type="text"
            placeholder="Поиск курсов"
            aria-label="Поиск курсов"
            v-model="pattern"
            @focus="isShowSearch = true"
            @blur="isShowSearch = false"
            @input="handleInput"
            @keyup.enter="searchByPattern"
        >
        <div v-if="pattern" class="clear_btn position-absolute">
          <button @click="clearPattern()" class="clear_btn">
            <p>X</p>
          </button>
        </div>
        <div @click="searchByPattern" class="input-group-append">
          <button class="btn btn-light">
            <img src="../../assets/icons/search.png" alt="search" id="search-icon">
          </button>
        </div>
      </div>
      <div class="presearch__suggests" v-show="isShowSearch">
        <ul v-for="course in getSearchCourses" :key="course.id" class="presearch__word-list">
          <li @mousedown="searchCourse(course.name)" class="presearch__word-li">{{ course.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: "CourseSearch",
  components: {},
  computed: mapGetters(['getSearchCourses', 'getFilterPattern']),
  data() {
    return {
      isShowSearch: true,
      pattern: '',
      courses: [],
    }
  },
  methods: {
    ...mapMutations(
        [
          'updateFilterPattern',
          'updateLoadingStatus',
          'clearFilterPattern',
          'clearSearchCourses'
        ]
    ),
    ...mapGetters(
        [
          'getFilterParams',
        ]
    ),
    handleInput() {
      if (this.pattern.length >= 3) {
        this.isShowSearch = true;
        this.updateFilterPattern(this.pattern);
        this.$store.dispatch('searchCourses');
      } else {
        this.clearFilterPattern();
        this.clearSearchCourses();
      }
    },
    clearPattern() {
      this.pattern = ''
      this.isShowSearch = false;
      this.updateFilterPattern(this.pattern);
      this.clearSearchCourses();
      this.emitter.emit('submitFilter')
    },
    searchByPattern() {
      this.isShowSearch = false;
      this.updateFilterPattern(this.pattern);
      this.emitter.emit('submitFilter')
      this.clearSearchCourses();
    },
    searchCourse(courseName) {
      this.pattern = courseName;
      this.searchByPattern();
    }
  },
  mounted() {
    this.pattern = this.getFilterPattern
  }
}
</script>

<style scoped>

.clear_btn {
  background: transparent;
  color: red;
  border: none;
  outline: none;
  z-index: 100;
  right: 55px;
  top: 5px;
  height: 30px;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.presearch__word-list {
  color: #898397;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.presearch__word-li {
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
  margin: 10px 10px 0 0;
  padding: 10px;
}

.presearch__suggests {
  -webkit-box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%);
  position: relative;
  top: 80%;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
}

#search-icon {
  height: 20px;
}
</style>
