import axios from 'axios'
import { API_COURSE_SIMILAR } from "@/common/config"

export default {
    actions: {
        fetchSimilar(context, id) {
            axios.get(API_COURSE_SIMILAR + id).then((response) => {
                const courses = response.data
                context.commit('updateCoursesSimilar', courses)
            })
        },
    },
    mutations: {
        updateCoursesSimilar(state, partners) {
            state.courses = partners
        }
    },
    state: {
       courses : []
    },
    getters: {
        getCoursesSimilar(state) {
            return state.courses
        }
    }
}
