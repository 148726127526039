<template>
  <swiper
      :slidesPerView="4"
      :spaceBetween="24"
      :navigation="true"
      :pagination="true"
      :modules="modules"
      class="mySwiper mb-2"
      @beforeTransitionStart="swipeCourseStat"
      :breakpoints="swiperOptions.breakpoints"
      v-if="coursesCompare.length > 0"
  >
    <swiper-slide class="mb-2" v-for="course in coursesCompare" :key="course.id">
      <SwiperItem :id="course.id"
                  :name="course.name"
                  :is_pro="course.is_pro"
                  :purprice="course.purprice"
                  :old_price="course.old_price"
                  :duration="course.duration"
                  :pic="course.pic"
                  :url="course.url"
                  @list="listId(course.id)">
      </SwiperItem>
    </swiper-slide>
  </swiper>
  <div class="course-info overflow-hidden" v-if="coursesCompare.length > 0 && !getCompareLoadingStatus">
    <div class="course-info__wrapper">
      <div class="text-dark course-info__wrapper_option">
        <span class="course-info__wrapper_option_name">
          Длительность
        </span>
      </div>
      <div class="course-info__wrapper_content row flex-nowrap" data-course>
        <div class="text-dark col-6 col-lg-3 gx-4 border-right" v-for="course in coursesCompare" :key="course">
          <div class="course-info__wrapper_content_item" id="course-duration">{{ durability(course.duration) }}</div>
        </div>
      </div>
    </div>
    <div class="course-info__wrapper">
      <div class="text-dark course-info__wrapper_option">
        <span class="course-info__wrapper_option_name">
          Технологии
        </span>
      </div>
      <div class="course-info__wrapper_content row flex-nowrap" data-course>
        <div class="text-dark col-6 col-lg-3 gx-4 border-right" v-for="course in coursesCompare" :key="course">
          <div class="course-info__wrapper_content_item">
            <div v-if="course.proglangs" class="course-info__content_item"><span v-for="proglang in course.proglangs"
                                                                                 :key="proglang"></span>{{
                course.proglangs
              }}
            </div>
            <div class="course-info__content_item" v-else>-</div>
          </div>
        </div>
      </div>
      <div class="course-info__wrapper">
        <div class="text-dark course-info__wrapper_option">
        <span class="course-info__wrapper_option_name">
          Уровень
        </span>
        </div>
        <div class="course-info__wrapper_content row flex-nowrap" data-course>
          <div class="text-dark col-6 col-lg-3 gx-4 border-right" v-for="course in coursesCompare" :key="course">
            <div class="course-info__wrapper_content_item">{{ level(course.is_pro) }}</div>
          </div>
        </div>
      </div>
      <div class="course-info__wrapper">
        <div class="text-dark course-info__wrapper_option">
          <span class="course-info__wrapper_option_name">
            Описание
          </span>
        </div>
        <div class="course-info__content row flex-nowrap" data-course>
          <div class="text-dark col-6 col-lg-3 gx-4 border-right" v-for="course in coursesCompare" :key="course">
            <div class="course-info__wrapper_content_item description">{{ course.description }}</div>
          </div>
        </div>
      </div>
      <div class="course-info__wrapper">
        <div class="text-dark course-info__wrapper_option"></div>
        <div class="course-info__wrapper_content row flex-nowrap" data-course>
          <div class="text-dark col-6 col-lg-3 gx-4 border-right" v-for="course in coursesCompare" :key="course">
            <div class="course-info__wrapper_content_delete-item">
              <button class="btn btn-light-gray" @click="removeFromCompare(course.id)">
                Удалить из сравнения
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperItem from './CourseCompareSwiperItem'
import {mapGetters, mapMutations} from "vuex"
import {API_COURSES_COMPARE} from "../../common/config"
import moment from "moment";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/navigation/navigation.scss"

// import required modules
import {Navigation, Pagination} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    SwiperItem
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCompareLoadingStatus', 'getCoursesCompareIds'])
  },
  methods: {
    ...mapMutations(['removeCoursesCompareId', 'updateLocalStorageId', 'updateCompareLoadingStatus']),
    async removeFromCompare(id) {
      this.coursesCompare = this.coursesCompare.filter((course) => {
        return course.id != id
      })
      await this.removeCoursesCompareId(id);

      this.emitter.emit("compareCount");
      this.emitter.emit('CheckCount');
    },
    listId(id) {
      let index = this.favoritesId.indexOf(id)
      if (index >= 0) {
        this.favoritesId.splice(index, 1)
      } else this.favoritesId.push(id)
      this.updateLocalStorageId(this.favoritesId)
      localStorage.setItem('coursesFavouriteIds', JSON.stringify(this.favoritesId))
      this.emitter.emit("FavoritesCount");
    },
    getCoursesCompare() {
      this.$axios({
        method: 'get',
        url: API_COURSES_COMPARE,
        params: {ids: this.coursesCompareIds},
      }).then((response) => {
        this.updateCompareLoadingStatus(false)
        this.coursesCompare = response.data
      })
    },
    durability(durability) {
      if (durability === 0) {
        return '-';
      } else {
        return moment.duration(durability, "seconds").humanize(false, {M: 1000})
      }
    },

    price(price) {
      return price > 0 ? this.formatMoney(price) + " ₽ " : "Бесплатно "
    },
    formatMoney(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".00", "");
    },
    level(is_pro) {
      let level = 'Начальный'
      if (is_pro) {
        level = 'Продвинутый'
      }
      return level
    },
    swipeCourseStat(swiper) {
      const listCourse = document.querySelectorAll('[data-course]')
      listCourse.forEach((el) => {
        el.style.transform = `translateX(${swiper.translate + 'px'})`
      })
    },
  },
  data() {
    return {
      coursesCompare: [],
      coursesCompareIds: [],
      favoritesId: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          771: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }
      }
    }
  },
  mounted() {
    this.coursesCompareIds = this.getCoursesCompareIds;
    if (this.coursesCompareIds) {
      this.getCoursesCompare()
    }

    const localStorageId = localStorage.getItem('coursesFavouriteIds');
    if (localStorageId){
      const localStorageArray = JSON.parse(localStorageId);
      this.favoritesId = localStorageArray.map(id => +id);
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  color: black;
}

.border-right {
  border-right: 1px dashed var(--remotza-royal-blue);
}

.course-info {
  &__content {
    padding: 0 12px;
  }
}

.gx-4 {
  margin-right: 7.5px;
  padding-right: 22px;
  padding-left: 0;
}

@media (max-width: 770px) {
  .gx-4 {
    margin-right: 5px;
    padding-right: 5px;
    padding-left: 0;
  }
  .course-info_description {
    min-height: 280px;
  }
  :deep .swiper-button-prev,
  :deep .swiper-button-next {
    display: none;
  }
  :deep .swiper-pagination {
    display: block !important;
  }
}

:deep .swiper-button-prev,
:deep .swiper-button-next {
  width: 40px;
  height: 48px;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: var(--remotza-royal-blue);

  &:after {
    font-family: swiper-icons;
    font-size: 30px;
  }
}

:deep .swiper-pagination {
  display: none;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 10px;
  height: auto;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  position: relative;
  height: 100%;
}

.course-info {
  border-radius: 5px;

  &__wrapper:nth-child(odd) {
    background-color: #FFFFFF;
  }

  &__wrapper {
    background-color: var(--pale-gray);

    &_option {
      padding-top: 13px;
      display: flex;

      &_name {
        font-size: 14px;
        color: #666;
        cursor: default;
        padding-left: 18px;
        padding-top: 5px;
        white-space: nowrap;
        position: relative;
      }

      &_name:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 30px;
        background-color: var(--remotza-royal-blue);
        margin-left: 6px;
        position: absolute;
        top: 11px;
        left: -2px;
      }
    }

    &_content {
      transition: transform .3s;
      padding: 0 11px;

      &_item {
        padding: 8px 18px;
        font-weight: 500;
        font-size: 17px;
        font-style: normal;
        text-align: left;
      }

      &_delete-item {
        padding: 8px 18px;
        text-align: center;
      }
    }
  }

}

</style>