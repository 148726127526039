<template>
  <div id="course-compare" class="anchor visually-hidden position-relative"></div>
  <section class="py-5">
    <div class="container">
      <div class="d-flex flex-column flex-lg-row">
        <h2 class="mb-3 text-violet-dark">Сравнение курсов</h2>
      </div>
      <div>
        <CourseCompareItem/>
      </div>
      <div class="custom-spinner pacman-center my-3" v-if="countCourse > 0">
        <pacman-loader :loading="getCompareLoadingStatus" :color="'#a259ff'"></pacman-loader>
      </div>
      <div v-else>
        <p class="text-dark-grey">Нет курсов для сравнения</p>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex"
import CourseCompareItem from '../course_compare/CourseCompareItem'
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue'

export default {
  name: "CourseCompareList",
  components: {
    PacmanLoader,
    CourseCompareItem,
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCompareLoadingStatus','getCoursesCompareIds'])
  },
  data() {
    return {
      coursesCompare: [],
      coursesCompareIds: [],
      countCourse: null
    }
  },
  mounted() {
    this.emitter.on('CheckCount', () => {
      if(this.countCourse > 0) this.countCourse--
    })
    this.countCourse = JSON.parse(localStorage.getItem('coursesCompareIds')).length
  }
}
</script>