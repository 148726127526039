let professions = {
    back: {
        id: 6,
        name: 'Back-end программист',
        score: 0
    },
    front: {
        id: 3,
        name: 'Front-end программист',
        score: 0
    },
    test: {
        id: 7,
        name: 'Тестировщик',
        score: 0
    },
    devops: {
        id: 10,
        name: 'DevOps',
        score: 0
    },
    security: {
        id: 11,
        name: 'Специалист по информационной безопасности',
        score: 0
    },
    designer: {
        id: 9,
        name: 'Дизайнер',
        score: 0
    },
    marketer: {
        id: 17,
        name: 'Интернет-маркетолог',
        score: 0
    },
    manager: {
        id: 18,
        name: 'Проджект менеджер',
        score: 0
    },
    analyst: {
        id: 15,
        name: 'Бизнес-аналитик',
        score: 0
    }
}

const quiz = {
    questions: [
        {
            text: "Представьте, что Вы с друзьями хотите построить шалаш, какую роль на себя возьмете?",
            responses: [
                {
                    text: 'Буду проектировать, чтобы был крепкий и удобный',
                    result: [
                        {
                            profession: 'analyst',
                            score: 1
                        },
                        {
                            profession: 'designer',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Буду непосредственно строить – обожаю процесс создания',
                    result: [
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Организую процесс среди друзей, чтобы каждый был полезен в общем деле',
                    result: [
                        {
                            profession: 'manager',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Буду участвовать в проверке прочности конструкции и помогать укреплять',
                    result: [
                        {
                            profession: 'test',
                            score: 1
                        },
                        {
                            profession: 'security',
                            score: 1
                        },
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "Вы с друзьями решили организовать (пере)продажу билетов на мероприятия, выберите подходящую роль для себя",
            responses: [
                {
                    text: 'Займусь сарафанкой и буду рассказывать всем и вся, что только у нас эксклюзивные билеты на первые места',
                    result: [
                        {
                            profession: 'marketer',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Займусь аналитикой – изучу рынок, определю спрос и точки сбыта',
                    result: [
                        {
                            profession: 'analyst',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Распределю роли в команде',
                    result: [
                        {
                            profession: 'manager',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Оформлю страницы в соц. сетях, чтобы все видели, что мы серьезная компания',
                    result: [
                        {
                            profession: 'marketer',
                            score: 1
                        },
                        {
                            profession: 'designer',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "От руководства пришла задача на отдел – подготовить открытку «С днем рождения» для генерального директора, ваши действия",
            responses: [
                {
                    text: 'Найду подходящие варианты в интернете и поправлю имя',
                    result: [
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Возьмусь рисовать открытку самостоятельно',
                    result: [
                        {
                            profession: 'front',
                            score: 1
                        },
                        {
                            profession: 'designer',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Найду ПО с пресетами для открыток',
                    result: [
                        {
                            profession: 'devops',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Поручу коллеге, у которого это получается',
                    result: [
                        {
                            profession: 'manager',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "Вас попросили спроектировать электросамокат для компании, которая сдает их в аренду. Чему Вы уделите больше внимания",
            responses: [
                {
                    text: 'Внешний вид – самокатов много, надо как-то выделяться',
                    result: [
                        {
                            profession: 'designer',
                            score: 1
                        },
                        {
                            profession: 'front',
                            score: 1
                        },
                        {
                            profession: 'marketer',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Запас хода от одного заряда – функциональность важнее',
                    result: [
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Безопасность – важно, чтобы этот самокат вернули)',
                    result: [
                        {
                            profession: 'security',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Изучу как работает связка самоката и программного обеспечения, чтобы понимать технологию и потенциальные проблемы',
                    result: [
                        {
                            profession: 'test',
                            score: 1
                        },
                        {
                            profession: 'devops',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "Вы берете автомобиль в аренду, что для Вас будет критично при выборе помимо стоимости",
            responses: [
                {
                    text: 'Внешний вид автомобиля, не хочется ездить на корыте',
                    result: [
                        {
                            profession: 'front',
                            score: 1
                        },
                        {
                            profession: 'designer',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Ходовые характеристики',
                    result: [
                        {
                            profession: 'test',
                            score: 1
                        },
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Начинка электроники',
                    result: [
                        {
                            profession: 'devops',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Безопасность для пассажиров',
                    result: [
                        {
                            profession: 'security',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Выгода и целесообразность аренды автомобиля',
                    result: [
                        {
                            profession: 'analyst',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "Вы собираетесь в отпуск, как будете выбирать отель для проживания?",
            responses: [
                {
                    text: 'Есть кровать – беру, я же не в номере жить буду все время',
                    result: [
                        {
                            profession: 'back',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Проанализирую местоположение, цены по рынку, предполагаемые маршруты и выберу наиболее подходящий вариант',
                    result: [
                        {
                            profession: 'analyst',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'В номере должно быть комфортно и красиво, я предпочту внешний вид лучшему местоположению',
                    result: [
                        {
                            profession: 'designer',
                            score: 1
                        },
                        {
                            profession: 'front',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Не люблю выбирать вслепую – иногда на фото совсем не то, что на самом деле. Выберу несколько вариантов и вживую на них посмотрю',
                    result: [
                        {
                            profession: 'test',
                            score: 1
                        }
                    ]
                },
                {
                    text: 'Сначала техническое оснащение, потом все остальное',
                    result: [
                        {
                            profession: 'devops',
                            score: 1
                        }
                    ]
                }
            ]
        },
        {
            text: "Если бы можно было выбрать только одно хобби на всю жизнь, то какой бы вариант Вы предпочли?",
            responses: [
                {
                    text: 'Рисование / Дизайн / Оформление интерьеров /Каллиграфия',
                    result: [
                        {
                            profession: 'designer',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Создание и/или сборка самодельных средств передвижения (ака Багги)',
                    result: [
                        {
                            profession: 'back',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Сборка пазлов всех видов и мастей',
                    result: [
                        {
                            profession: 'front',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Буду играть в шахматы и монополию',
                    result: [
                        {
                            profession: 'analyst',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Заведу свой блог – буду вещать о всяких интересностях',
                    result: [
                        {
                            profession: 'marketer',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Буду собирать систему умный дом для себя и автоматизировать все что можно',
                    result: [
                        {
                            profession: 'devops',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Организую новое совместное хобби среди своих друзей – скалолазание/походы/трекинг/велопоходы',
                    result: [
                        {
                            profession: 'manager',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Обожаю задачи типа «найди 10 отличий на картинках»',
                    result: [
                        {
                            profession: 'test',
                            score: 2
                        }
                    ]
                },
                {
                    text: 'Буду решать головоломки с утра до вечера',
                    result: [
                        {
                            profession: 'security',
                            score: 2
                        }
                    ]
                }
            ]
        },
    ]
}

export default {
    professions: professions,
    quiz: quiz
}