<template>
  <div id="test" ref="test" class="anchor visually-hidden position-relative"></div>
  <section class="test-section">
    <div class="container mb-3">
      <div class="prof-test">
        <div class="row">
          <div class="col-12 col-sm-10 col-lg-9">
            <h2 class="title_test">Пройди краткий <span>опрос на профориентацию</span> и получи рекомендации по
              курсам</h2>
          </div>
          <div class="col-12 col-sm-2 col-lg-3">
            <div v-if="questionIndex !== quiz.questions.length" class="number_questions">
              {{ questionIndex + 1 }} / {{ quiz.questions.length }}
            </div>
          </div>
        </div>

        <k-progress class="my-2 mx-0" :percent=getProgressBarPercents bg-color="#EFF2FF" color="#4970FF"
                    line-height="5" :show-text="false"></k-progress>
        <div v-for="(question, index) in quiz.questions" :key="question.id">
          <div v-show="index === questionIndex" class="p-2 p-lg-4">
            <p class="question">{{ question.text }}</p>
            <div class="list-group list-group-flush list-unstyled">
              <label class="list-group-item" v-for="(response, i) in question.responses" :key="response.id">
                <input
                    class="form-check-input"
                    type="radio"
                    :id="'' + index + i"
                    :value="response.result"
                    :name="index"
                    v-model="userResponses[index]"
                >
                <span>{{ response.text }}</span>
              </label>
            </div>
            <div class="mt-4">
              <button class="btn btn-light-gray px-4 py-1 mb-2 mb-xl-0 me-4" :disabled="questionIndex <= 0"
                      @click="prev">
                ← Назад
              </button>
              <button class="btn btn-blue float-end px-4 py-1 mb-2 mb-xl-0" @click="next"
                      :disabled="disableNextButton(questionIndex)">
                Далее →
              </button>
            </div>
          </div>
        </div>
        <div v-if="questionIndex === quiz.questions.length" class="p-2 p-lg-4">
          <p class="fw-500">По результатам теста вам подойдут следующие профессии:</p>
          <ul>
            <li v-for="profession in getResults" :key="profession.id">
              {{ professions[profession].name }}
            </li>
          </ul>
          <button @click="goFilter()" class="btn btn-blue mb-2 mb-xl-0 me-xl-3">
            Посмотреть подходящие курсы
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <FAQ :questions="faq"></FAQ>
    </div>
  </section>
</template>

<script>
import dataFAQ from "../data/faq"
import data from "../data/profession"
import FAQ from "./ui/faq/FAQ"
import {mapMutations} from "vuex"
import {useGtag} from "vue-gtag-next"

export default {
  name: "ProfessionTest",
  components: {
    FAQ
  },
  data() {
    return {
      professions: data.professions,
      quiz: data.quiz,
      questionIndex: 0,
      userResponses: [],
      responseCount: 0,
      prof: [],
      faq: dataFAQ.faq,
      isShow: false,
    }
  },
  computed: {
    getResults: function () {
      if (['staging', 'production'].includes(process.env.NODE_ENV)) {
        window.ym(74191996, 'reachGoal', 'test')
        const {event} = useGtag()
        event('lead', {
          'event_category': 'cat1',
          'event_action': 'proftest'
        })
      }
      return this.getResultProfessions();
    },
    getProgressBarPercents: function () {
      return Math.floor(this.responseCount / this.quiz.questions.length * 100);
    }
  },
  methods: {
    ...mapMutations(
        [
          'updateLoadingStatus',
          'updateFilterProfessions',
          'clearFilterProfessions',
        ]
    ),
    next: function () {
      this.responseCount = this.userResponses.length
      this.questionIndex++
      this.scroll()
    },
    prev: function () {
      this.responseCount = this.userResponses.length
      this.questionIndex--
      this.scroll()
    },
    scroll() {
      this.$refs.test.scrollIntoView()
    },
    disableNextButton(index) {
      return !(index in this.userResponses);
    },
    getResultProfessions: function () {
      let maxScore = 0;

      for (let answer of this.userResponses) {
        let profession = answer[0]['profession'];
        let score = answer[0]['score'];
        this.professions[profession].score += score;

        if (this.professions[profession]['score'] > maxScore) {
          maxScore = this.professions[profession]['score']
        }
      }

      let resProfessions = [];

      for (let answer of this.userResponses) {
        let profession = answer[0]['profession'];

        if (this.professions[profession]['score'] === maxScore) {
          resProfessions.push(profession)
        }
      }

      return resProfessions.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    goFilter() {
      this.updateLoadingStatus(true)
      this.clearFilterProfessions()
      this.getResults.forEach((profession) => {
        this.updateFilterProfessions(this.professions[profession].id)
      })
      this.$store.dispatch('applyFilter')
      this.$router.push({path: '/courses', hash: ''})
    }
  }
}
</script>

<style>
.k-progress-outer {
  padding-right: 0 !important;
}

.k-progress-outer-line {
  border-radius: 0;
}
</style>

<style lang="scss" scoped>
.test-section {
  position: relative;
  padding: 15px 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%), linear-gradient(0deg, var(--pale-gray), var(--pale-gray));

  .prof-test {
    background: white;
    color: var(--deep-gray);

    .number_questions {
      color: var(--smoky);
      font-size: 20px;
      padding: 8px;
      float: right;
    }

    .title_test {
      font-size: 33px;
      padding: 20px;
      margin: 0;

      span {
        color: var(--remotza-slate-blue);
      }
    }

    .question {
      font-size: 22px;
      color: var(--deep-gray);
      font-weight: 500;
    }

    .list-group-item {
      color: unset !important;
      background-color: unset !important;
      border: none !important;

      .form-check-input {
        position: relative;
        margin-right: 0.5rem;
      }

      .form-check-input:focus {
        box-shadow: 0 0 0 0.25rem rgba(73, 112, 225, 0.1);
      }

      .form-check-input:checked {
        background-color: white !important;
        border-color: #DFDFFF !important;
      }

      .form-check-input:checked[type=radio]:before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 8px;
        height: 8px;
        background: var(--remotza-royal-blue);
        border-radius: 50%;
      }

      .form-check-label {
        padding-left: 0.5rem;
      }
    }
  }

  .arrow:hover path {
    fill: var(--remotza-royal-blue);
    transition: 300ms ease;
  }

}

@media (min-width: 768px) {
  .test-section {
    .prof-test {
      .number_questions {
        padding: 20px;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .test-section {
    padding: 50px 0;

    .prof-test {
      .title_test {
        font-size: 25px;
        padding: 8px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .test-section {
    min-height: 386px;
  }

  .arrow svg {
    width: 30px;
    height: 30px;
  }
}
</style>
