<template>
	<canvas id="barChart" width="200" height="150"></canvas>
</template>

<script>

  import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );

  export default {
    name: 'BarChart',
    props: {
      barChartData: {
        type: Object,
        required: true,
        default: null
      }
    },
    mounted() {
      var ctx = document.getElementById('barChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: this.barChartData,
        options: {
          layout: {
            padding: {
              top: 20
            }
          }
          ,
          categoryPercentage: 0.3,
          plugins: {
            legend: {display: false},
            title: {
              display: false,
            },
          },
          animation: {
            duration: 3000,
            onComplete: function () {
              this.ctx.textAlign = 'center'
              this.ctx.textBaseline = 'bottom'
              this.data.datasets.forEach((dataset, i) => {
                let meta = this.getDatasetMeta(i)
                meta.data.forEach((bar, index) => {
                  let data = dataset.data[index]
                  ctx.fillText(data, bar.x, bar.y - 5)
                })
              })
            }
          },
          responsive: false,
          scales: {
            y: {
              display: false,
              ticks: {
                display: false,
                beginAtZero: true
              },
            },
            x: {
              display: true,
              grid: {
                display: false,
                drawBorder: false
              }
            },
          }
        }
      });
    }
  }
</script>
