<template>
  <div class="col mb-4 position-relative">
    <div class="card">
      <div class="img-card">
        <div class="price-card">
          <div class="card-text"><span class="pe-2 fw-bold">{{ price }}</span>
            <span v-if="old_price > 0" class="text-decoration-line-through">
              {{ old_price }} ₽
            </span>
          </div>
          <div v-if="purprice > 0 && duration > 0 && getMonthPrice !== 0" class="card-text">
            <span class="pe-2 fw-bold">{{ getMonthPrice }}</span>
            <span class="fw-bold"> ₽ /мес</span>
          </div>
        </div>
        <div v-if="is_pro" class="card-pro-label position-absolute text-center rounded-circle">PRO</div>
        <img
            :src="pic"
            @error="$event.target.src='https://netology.ru/backend/assets/zaglushka-f55af4c722ba5aaad9741465c1337e0220453875d19278e5d5c96ab86bf7ac0f.png'"
            :alt="name" class="object-fit-cover w-100 h-100" loading="lazy">
      </div>
      <div class="card-body">
        <div class="title-favorites-spacing">
          <h4 class="card-title d-block name_course">{{ name }}</h4>
          <div class="card-communications">
            <button type="button" title="Добавить в сравнение" :class="{ activeCompare }" @click="addToCompare()">
              <svg>
                <use xlink:href="#iconCompare"></use>
              </svg>
            </button>
            <button type="button" title="Добавить в избранное" :class="{ activeFavorites }" @click="saveInLocalStorage">
              <svg>
                <use xlink:href="#iconFavorites"></use>
              </svg>
            </button>
          </div>
        </div>

        <span class="card-title d-block school">{{ school }}</span>
        <span class="card-title d-block">Уровень: {{ level }}</span>
        <span v-if="duration > 0" class="card-title d-block">Длительность: {{ durability }}</span>
        <h4 class="h6 card-subtitle mb-2 fw-normal">
          <button v-for="technology in technologies" :key="technology" @click="filter(technology.id)"
                  class="technologies">{{ technology.name }}
          </button>
        </h4>
      </div>
      <div class="bg-white border-0 card-footer d-flex flex-column mb-2">
        <a :href="url" class="btn btn-blue mb-1" target="_blank" rel="noreferrer"
           @click="incrementCourseStat">
          Пройти курс →
        </a>
        <button type="button" class="btn btn-light-gray" @click="showModal">Подробнее →</button>
      </div>
    </div>
  </div>
  <svg style="display:none;">
    <symbol id="iconFavorites" viewBox="0 0 20 20">
      <g>
        <path fill-rule="evenodd"
              d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.178.178 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.178.178 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.178.178 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.178.178 0 0 1-.134-.098L8.16 4.1z"/>
      </g>
    </symbol>
  </svg>
  <svg style="display:none;">
    <symbol id="iconCompare" viewBox="0 0 20 20">
      <g>
        <path
            d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
      </g>
    </symbol>
  </svg>
</template>

<script>
import moment from 'moment'
import {API_COURSE_STAT} from "../../common/config"
import {useGtag} from "vue-gtag-next"
import {mapMutations, mapGetters} from "vuex"

moment.locale('ru')

export default {
  name: "CourseItem",
  props: {
    id: {
      type: Number,
      required: true,
      default: null
    },
    name: {
      type: String,
      required: true,
      default: null
    },
    school: {
      type: String,
      required: true,
      default: null
    },
    is_pro: {
      type: Number,
      required: true,
      default: 0
    },
    duration: {
      type: Number,
      required: false,
      default: null
    },
    old_price: {
      type: String,
      required: false,
      default: '0'
    },
    purprice: {
      type: String,
      required: true,
      default: '0'
    },
    pic: {
      type: String,
      required: true,
      default: null
    },
    url: {
      type: String,
      required: true,
      default: null
    },
    technologies: {
      type: Object,
      default: null
    },
    page_context: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeFavorites: false,
      activeCompare: false,
      activeCompareIds: [],
    };
  },

  computed: {
    ...mapGetters(['getCoursesCompareIds']),
    level() {
      let level = 'Начальный'
      if (this.$props.is_pro) {
        level = 'Продвинутый'
      }
      return level
    },
    durability() {
      return moment.duration(this.$props.duration, "seconds").humanize(false, {M: 1000})
    },
    price() {
      return this.$props.purprice > 0 ? this.formatMoney(this.$props.purprice) + " ₽ " : "Бесплатно "
    },
    getMonthPrice() {
      let months = Math.round(moment.duration(this.$props.duration, "seconds").asMonths())
      return months !== 0 ? Math.floor(this.$props.purprice / months) : 0
    }
  },
  methods: {
    ...mapMutations(
        [
          'updateLoadingStatus',
          'updateFilterProglang',
          'removeCoursesCompareId'
        ]
    ),
    saveInLocalStorage() {
      this.$emit('list')
      if (!this.activeFavorites) this.activeFavorites = true
      else this.activeFavorites = false;
    },
    async addToCompare() {
      if(!this.activeCompare) this.activeCompare = true
      else this.activeCompare = false
      this.$emit('ids')
    },
    scrollIntoElement(id) {
      const el = document.querySelector(id);
      if (el) {
        el.scrollIntoView();
      }
    },
    filter(id) {
      this.scrollIntoElement('#course')
      this.updateLoadingStatus(true)
      this.updateFilterProglang(id)
      this.$store.dispatch('applyFilter')
    },
    showModal() {
      this.$emit('setCourse')
      this.$emit('update')
    },
    incrementCourseStat() {
      this.track()
      this.$axios({
        method: 'post',
        url: API_COURSE_STAT,
        data: {
          id: this.id
        },
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      })
    },
    formatMoney(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".00", "");
    },
    track() {
      window.fbq('track', 'ViewContent')
      window.ym(74191996, 'reachGoal', 'click')

      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({"type": "reachGoal", "id": 3226873, "goal": "partner"});

      const {event} = useGtag()
      event('lead', {
        'event_category': 'cat1',
        'event_action': 'click',
        'event_label': 'course'
      })
    },
  },
  mounted() {
    const localStorageId = localStorage.getItem('coursesFavouriteIds');

    if (localStorageId) {
      const localStorageArray = JSON.parse(localStorageId);
      for (let id of localStorageArray) {
        if (id === this.id) {
          this.activeFavorites = true
        }
      }
    }
    const courseCompareId = this.getCoursesCompareIds

    if (courseCompareId) {
      for (let id of courseCompareId) {
        if (id == this.id) {
          this.activeCompare = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  border-radius: 10px 10px;
  border: 0;

  .img-card {
    height: 200px;
    border-radius: 10px 10px 0 0;
    width: 100%;

    .price-card {
      position: absolute;
      color: var(--remotza-slate-blue);
      border: 1px solid var(--remotza-slate-blue);
      background-color: #fff;
      padding: 0.375rem 0.75rem;
      display: inline-block;
      white-space: nowrap;
      text-align: center;
      font-weight: 400;
      line-height: 1.5;
      font-size: 15px;
      border-radius: 5px;
      top: 23%;
      right: 5%;
    }

    img {
      border-radius: 10px 10px 0 0;
    }
  }

  &:hover {
    transition: box-shadow 0.8s;
    -webkit-box-shadow: 4px 4px 14px 6px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 14px 6px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 14px 6px rgba(34, 60, 80, 0.2);
  }
}

.object-fit-cover {
  object-fit: cover;
}

.card-pro-label {
  font-size: 12px;
  font-family: Roboto, Arial, sans-serif;
  width: 50px;
  height: 50px;
  color: #00fffd;
  background-color: #210c99;
  right: 10px;
  top: 10px;
  padding: 17px 10px 0;
}

.card-communications {
  display: flex;
  align-items: baseline;

  button {
    border: none;
    background: none;
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      stroke: var(--remotza-royal-blue);
      stroke-width: 1px;
      fill: none;
    }
  }
}

.activeFavorites:deep,
.activeCompare:deep {
  svg {
    fill: var(--remotza-royal-blue) !important;
    stroke: none !important;
  }
}

.title-favorites-spacing {
  display: flex;
  justify-content: space-between;
}


.card-body {
  color: var(--deep-gray);

  .name_course {
    font-weight: 500;
    font-size: 20px;
  }

  .school {
    color: var(--remotza-slate-blue);
    font-weight: 500;
    font-size: 16px;
  }

  .technologies {
    color: var(--smoky);
    background-color: #E7EAFA;
    margin-right: 4px;
    border: none;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 3px;
    margin-bottom: 4px;
  }
}

</style>
