<template>
	<div class="nav-tab-content">
		<div class="position-relative" id="nav-front">
			<img src="../../assets/professionpage/Frontend_characterpr.png" class="position-absolute d-none d-sm-block">
			<div class="row pb-4">
				<div class="col-12 col-md-6 position-relative z-index-1">
					<h3 class="pb-4">Фронт-енд разработчик</h3>
					<a @click="goFilter()" class="btn btn-outline-blue-course me-md-3 mb-2 mb-md-0">Выбрать курс фронт-енд программирования →</a>
				</div>
				<div class="col-12 col-md-6 z-index-1">Front end программирование - верстка и создание визуальной части сайта. Того, что пользователь увидит на экране - шрифт, дизайн, графические элементы, внешний вид кнопок, анимация или всплывающие окна. На освоение front end потребуется около 3 - 6 месяцев.</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/skills_icon.png" alt="">
					<span class="ms-3 text-for-img">cамые необходимые навыки</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">HTML | CSS | JavaScript | Git | React</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/demand_icon.png" alt="">
					<span class="ms-3 text-for-img">на что растёт спрос</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">Vue.js | React Native | Webpack</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/cash_icon.png" alt="">
					<span class="ms-3 text-for-img">медиана заработных плат</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">92 000 ₽ — все
					<span class="d-block"></span>45 000 ₽ — новички
				</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/need_icon.png" alt="">
					<span class="ms-2 text-for-img">востребованность
						<span class="mt-2 d-block bar-chart-descr">число таких вакансий на тысячу</span>
					</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">
					<BarChart v-bind:barChartData="barChartData"/>
				</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/expirience_icon.png" alt="">
					<span class="ms-3 text-for-img">требуемый опыт</span>
				</div>
				<div class="col-12 col-md-6 mt-3 fs-8">
					<span class="prof-descr">
						<DoughnutChart v-bind:doughnutChartData="doughnutChartData"/>
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col info">
					По данным Яндекс и Headhunter, 2016 – 2023
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {defineComponent} from 'vue'
import BarChart from "../charts/BarChart";
import DoughnutChart from "../charts/DoughnutChart";
import {mapMutations} from "vuex";

export default defineComponent({
  name: "TabFront",
  components: {
    DoughnutChart,
    BarChart
  },
  data() {
    return {
      barChartData: {
        labels: [2020, 2021, 2022],
        datasets: [
          {
            backgroundColor: "#babae8",
            data: [2, 2.36, 2.39]
          }
        ]
      },
      doughnutChartData: {
        labels: ["Больше 6 лет", "От 4 до 6 лет", "От 1 до 3 лет", "До года"],
        datasets: [
          {
            backgroundColor: ["#520abf", "#9f4d74", "#ffbd23", "#e5869d"],
            data: [3, 20, 60, 17],
            borderWidth: 1
          }
        ]
      }

    }
  },
  methods : {
    ...mapMutations(
        [
          'updateLoadingStatus',
          'updateFilterProfessions',
          'clearFilterProfessions',
        ]
    ),
    goFilter(){
      this.updateLoadingStatus(true)

      this.$router.push({ path: '/courses' , query: {filter_proglang: 310}})
    }
  }


})
</script>

<style lang="scss">
#nav-front > img {
  left: 40%;
  top: 10%;
  transform: translateX(-40%)
}

.prof-descr {
  font-weight: 500;

  span {
    top: 50%;
    left: 50%;
    transform: translate(-80%, -50%);
  }
}

.info {
  color: var(--dark-grey);
  font-size: 12px;
}

.bar-chart-descr {
  color: var(--remotza-violet-dark);
  font-size: 14px;
  opacity: 0.65;
}

.text-for-img {
  position: absolute;
  font-size: 20px;
  top: 10px;
  font-weight: 400;
}

@media (max-width: 575.98px) {
  .prof-descr {
    font-size: 13px !important;
  }
  .text-for-img {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}
</style>