<template>
  <div class="bg-body mock"></div>
  <div class="bg-body">
    <CourseSearch v-if="!getLoadingStatus"/>
    <div class="container d-lg-flex">
      <CourseFilter/>
      <CourseList>
        <template v-slot:button v-if="getLastCourseLength === limit">
          <button @click="moreCourses()" class="btn btn-blue w-100 p-3 fw-500">Загрузить ещё</button>
        </template>
      </CourseList>
    </div>
  </div>
</template>

<script>
import CourseList from "../components/course/CourseList"
import CourseFilter from "../components/course/CourseFilter"
import {MOBILE_COURSE_LIMIT, DESKTOP_COURSE_LIMIT} from "../common/index"
import {mapGetters, mapMutations, mapActions} from "vuex"
import CourseSearch from '@/components/course/CourseSearch'

export default {
  name: 'Courses',
  components: {
    CourseList,
    CourseFilter,
    CourseSearch
  },
  data() {
    return {
      limit: 15
    }
  },
  created() {
    this.limit = this.isMobile() ? MOBILE_COURSE_LIMIT : DESKTOP_COURSE_LIMIT
    this.clearStateCourses()
    this.fetchPromo()
    this.updateLimit(this.limit)
    this.applyFilter()
    if (this.$route.params.profession_id) {
      let profession_id = this.$route.params.profession_id.toLowerCase()
      this.updateFilterProfessions(profession_id)
    }
  },
  computed: {
    ...mapGetters([
        'getCourses', 'courseLength', 'getCountPromo', 'getLoadingStatus', 'getLastCourseLength', 'isMobile'
    ]),

  },
  methods: {
    ...mapMutations([      'updatePage',
      'updateLimit',
      'updateFilterProfessions',
      'updateFilterProglang',
      'updateFilterSchools',
      'updateFilterPriceMax',
      'updateFilterPriceMin',
      'updateFilterLevel',
      'updateFilterPriceMin',
      'updateFilterPattern',
      'updateFilterTime',
      'clearPage',
      'updateFilterSort',
      'clearStateCourses',
    ]),
    ...mapActions(['fetchCourses', 'fetchPromo']),
    moreCourses() {
      this.updatePage()
      // this.$store.dispatch('fetchCourses')
      this.applyFilter()
    },
    applyFilter() {
      const getParams = this.$route.query;

      console.log(getParams)

      this.updateFilterProglang(getParams.filter_proglang ?? null)
      this.updateFilterProfessions( this.prepareArrayUrl(getParams.filter_professions) ?? [])
      this.updateFilterPriceMin(getParams.filter_min)
      this.updateFilterPriceMax(getParams.filter_max)
      this.updateFilterTime(this.prepareArrayUrl(getParams.filter_time)  ?? [])
      this.updateFilterSchools(this.prepareArrayUrl(getParams.filter_school) ?? [])
      this.updateFilterLevel(this.prepareArrayUrl(getParams.filter_level) ?? [])
      this.updateFilterPattern(getParams.pattern
          ?? null)
      this.updateFilterSort(getParams.sort ?? null)
      this.fetchCourses()

    },
    prepareArrayUrl (urlParams) {
      let proper;

      if(typeof(urlParams) != "undefined" ){
        proper = (Array.isArray(urlParams)) ? urlParams : [urlParams];
      }
      return proper

    },
  },
}
</script>

<style scoped>
.mock {
  padding-top: 100px;
}
</style>