import axios from 'axios'
import {
    API_PROGLANGS,
} from "@/common/config"

export default {
    actions: {
        fetchProglangs(context) {
            axios.get(API_PROGLANGS).then((response) => {
                const proglangs = response.data
                context.commit('updateProglangs', proglangs)
                localStorage.setItem('proglangs', JSON.stringify(proglangs));
                // трасформируем полученные proglangs в формат proglangsSlug и  сохраняем в vuex store
                let proglangsSlug = {}
                context.getters.allProglangs.map(({name, value}) => {
                    let slug = name.toLowerCase();
                    return proglangsSlug[`${slug}`] = value;
                });
                context.commit('updateProglangsSlug', proglangsSlug);
                localStorage.setItem('proglangsSlug', JSON.stringify(proglangsSlug));
            })
        }
    },
    mutations: {
        updateProglangs(state, proglangs) {
            state.proglangs = proglangs
        }, updateProglangsSlug(state, proglangsSlug) {
            state.proglangsSlug = proglangsSlug;
        },
    },
    state: {
        proglangs: [],
        proglangsSlug: [],
    },
    getters: {
        allProglangs(state) {
            return state.proglangs;
        },
        getAllProglangsSlug(state) {
            return state.proglangsSlug
        },
        getProglangIdBySlugName: state => slug => {
            return state.proglangsSlug[slug]
        },
    }
}