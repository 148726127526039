<template>
	<canvas id="doughnutChart" width="280" height="150"></canvas>
</template>

<script>

  import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  } from 'chart.js';

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );


  export default {
    name: "DoughnutChart",
    props: {
      doughnutChartData: {
        type: Object,
        required: true,
        default: null
      }
    },
    mounted() {
      var ctx = document.getElementById('doughnutChart').getContext('2d');
      new Chart(ctx, {
        type: 'doughnut',
        data: this.doughnutChartData,
        options: {
          plugins: {
            legend: {
              position: 'right',
              onClick: function () {
                return
              }
            },
            title: {
              display: false,
            },
          },
          animation: {
            duration: 3000
          },
          responsive: false,
        }
      });
    }
  }
</script>
