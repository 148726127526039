<template>
	<transition name="fadef">
		<div class="modal-box" v-show="isShow">
			<transition name="fade">
				<div class="flex-col modal-main">
					<div class="modal-header">
						<p class="h5 modal-title text-violet-dark">Политика конфиденциальности</p>
						<button @click="$emit('close')" type="button" class="btn-close"></button>
					</div>
					<div class="modal-body mt-3 mb-3 text-dark">
						<slot></slot>
					</div>
					<div class="modal-footer">
						<button @click="$emit('close')" type="button" class="btn btn-outline-lilac-light">Закрыть</button>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
  export default {
    name: "ModalText",
    props: {
      isShow: {
        type: Boolean,
        default: false
      },
      modalText: {
        type: Object,
        default: null
      }
    }
  }
</script>
