export const API_COURSE = '/api/v1/courses';
export const API_SUBSCRIBE = '/api/v1/subscribe';
export const API_PROFESSION = '/api/v1/professions';
export const API_PARTNER = '/api/v1/partners';
export const API_FEEDBACK = '/api/v1/feedback';
export const API_ARTICLE = '/api/v1/article';
export const API_KNOWLEDGE_FEEDBACK = '/api/v1/knowledge-feedback';
export const API_COURSE_STAT = '/api/v1/course-stat';
export const API_PERSON_INFO = '/api/v1/person-info';
export const API_COURSE_SIMILAR = '/api/v1/course/similar/';//здесь нужно добавлять id курса
export const API_PROMO = '/api/v1/promos';
export const API_COURSES_COMPARE = '/api/v1/courses/compare';
export const API_LOGIN = '/api/v1/auth/login';
export const API_REGISTER = '/api/v1/auth/signup';
export const API_USER = '/api/v1/user';
export const API_UPDATE_USER_PASSWORD = '/api/v1/user/update-password';
export const API_UPDATE_USER_AVATAR = '/api/v1/user/update-avatar';
export const API_PROGLANGS = '/api/v1/proglangs';