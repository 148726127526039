<template>
  <MainScreen></MainScreen>
  <ProfessionTest></ProfessionTest>
  <CourseList>
    <template v-slot:button>
      <router-link to="/courses" class="btn btn-light-gray more-course">
        Перейти к полному списку →
      </router-link>
    </template>
  </CourseList>
  <MentorForm></MentorForm>
  <WorkForm></WorkForm>
</template>

<script>

import MainScreen from "../components/MainScreen"
import ProfessionTest from "../components/ProfessionTest"
import CourseList from "../components/course/CourseList"
import MentorForm from "../components/forms/MentorForm"
import WorkForm from "../components/forms/WorkForm"
import {LANDING_COURSE_LIMIT} from "../common/index"
import {mapActions, mapMutations} from "vuex"

export default {
  name: 'Home',
  components: {
    MainScreen,
    ProfessionTest,
    CourseList,
    MentorForm,
    WorkForm,
  },
  created() {
    this.allClearFilter();
    this.clearPage();
    this.clearStateCourses();
    this.updateLimit(LANDING_COURSE_LIMIT);
    this.fetchCourses();
  },
  methods: {
    ...mapMutations(['updatePage', 'updateLimit', 'allClearFilter', 'clearStateCourses', 'clearPage']),
    ...mapActions(['fetchCourses']),
  }
}
</script>
<style lang="scss" scoped>

.more-course {
  width: 100%;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
}

</style>