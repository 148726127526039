import {createApp, ref} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue3-cookies'
import VueGtag from 'vue-gtag-next'
import Notifications from '@kyvg/vue3-notification'
import KProgress from 'k-progress-v3'
import mitt from 'mitt'

const emitter = mitt()

import 'jquery'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import "@/styles/app.scss"

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueCookies, {expireTimes: "30d"})
    app.config.globalProperties.emitter = emitter;

app.use(Notifications);
app.component('k-progress', KProgress)

if (['staging', 'production'].includes(process.env.NODE_ENV)) {
    app.use(VueGtag, {
        property: {
            id: 'UA-194065462-1',
            params: {
                send_page_view: true,
                anonymize_ip: true
            }
        },
        router
    })
}

app.config.globalProperties.$axios = axios
app.mount('#app')

export const bus = ref(new Map());
