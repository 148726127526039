<template>
  <transition>
    <div class="modal-box" @click="closeModal()" v-show="isShow">
      <div class="container modal-width">
        <div class="modal-content">
          <div class=" modal-header d-sm-block d-md-none d-lg-none d-xl-none">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="closeModal"></button>
          </div>
          <div class="row">
            <div class="d-none d-lg-block col-lg-6 wrapper-image">
              <div class="card-image" :style="{backgroundImage : 'url(' + image + ')' }"></div>
            </div>
            <div class="col-lg-6">
              <div class="modal-body">
                <h5 class="h5 pb-3 text-blue">{{ course.name }}</h5>
                <img class="card-img-partner" :src="partnerImage" :alt=course.partner.name>
                <p class="pt-3 fs-5">
                  <span class="text-blue">{{ price }}</span>
                  <span v-if="course.old_price > 0"
                        class="text-decoration-line-through text-navbar-light-color">{{ course.old_price }} ₽</span>
                </p>
                <p class="text-gray-pale">{{ course.description }}</p>
                <p class="text-gray-pale">Уровень:
                  <span>{{ level }}</span></p>
                <p v-if="course.duration > 0" class="text-gray-pale">Длительность:
                  <span >{{ durability }}</span></p>
                <div class="modal-section" v-for="(sectionItem) in page_context " v-bind:key="sectionItem">
                  <span class="text-gray-pale"> {{ sectionItem.title }}</span>
                  <ul style="margin: 0;">
                    <li v-for="(item) in sectionItem.items" v-bind:key="item">
                      <span class="text-gray-pale" v-if="item.title">{{ item.title }}</span>
                      <p style="margin: 0;padding: 0" class="text-gray-pale" v-if="item.description">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
                <div class="modal-footer px-0">
<!--                  <button v-if="!inCompare"-->
<!--                          class="btn btn-blue btn-sm-block"-->
<!--                          @click="addToCompare(course.id)">-->
<!--                     Добавить в сравнение-->
<!--                  </button>-->
<!--                  <router-link class="btn btn-light-gray btn-sm-block" v-else to="/courses_compare">Перейти в сравнение</router-link>-->
                  <a :href="course.url" class="btn btn-blue btn-sm-block" target="_blank"
                     @click="incrementCourseStat">
                    Пройти курс
                  </a>
                  <button type="button" class="btn btn-light-gray btn-sm-block" @click="closeModal(true)">
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import '@/styles/components/carousel/carousel.css'
import moment from "moment"
import {API_COURSE_STAT} from "../../common/config";
import {useGtag} from "vue-gtag-next";
import {mapGetters} from "vuex";

export default {
  name: "CourseModal",
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false
    },
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getCoursesCompareIds']),
//TODO тут повторяется и надо переделать
    level() {
      let level = 'Начальный'
      if (this.$props.course.is_pro) {
        level = 'Продвинутый'
      }
      return level
    },
    page_context() {
      if (this.$props.course.page_context === null || this.$props.course.page_context === undefined) {
        return [];
      }

      return JSON.parse(this.$props.course.page_context);
    },
    partnerImage() {
      const partner = this.$store.getters.partnerFindById(this.course.partner.id)
      return partner.stylized_pic
    },
    durability() {
      return moment.duration(this.$props.course.duration, "seconds").humanize()
    },
    price() {
      return this.$props.course.purprice > 0 ? this.formatMoney(this.$props.course.purprice) + " ₽ " : "Бесплатно "
    },
    image() {
      return this.$props.course.pic
    },
    inCompare() {
      return this.getCoursesCompareIds.includes(this.$props.course.id)
    },
  },
  methods: {
    closeModal(param) {
      if (param) {
        this.$emit('update')
      } else {
        const path = event.path || (event.composedPath && event.composedPath())
        if (path[0].classList.value === 'modal-box') {
          this.$emit('update')
        }
      }
    },
    // addToCompare(id) {
    //   this.$store.commit('addCoursesCompareId', id)
    // },
    incrementCourseStat() {
      this.track()
      this.$axios({
        method: 'post',
        url: API_COURSE_STAT,
        data: {
          id: this.course.id
        },
        headers: {"Content-Type": "application/json; charset=UTF-8"}
      })
    },
    formatMoney(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".00", "");
    },
    track() {
      window.fbq('track', 'ViewContent')
      window.ym(74191996, 'reachGoal', 'click')

      var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({"type": "reachGoal", "id": 3226873, "goal": "partner"});

      const {event} = useGtag()
      event('lead', {
        'event_category': 'cat1',
        'event_action': 'click',
        'event_label': 'course'
      })
    },
  }
}
</script>
<style scoped lang="scss">
.card-img-partner {
  max-width: 200px;
}

@media (max-width: 990px) {
  .wrapper-image {
    height: 70px;
  }
}

.modal-section {
  flex-wrap: wrap;
  text-align: left;
  justify-content: flex-end;
  padding-bottom: 1rem;
  font-size: 12pt;
  color: #1a1e21;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.card-image {
  height: 100%;
  background-position: center;
  background-size: cover;
}

@media (max-width: 576px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

.modal-box {
  top: 5%;
}
</style>