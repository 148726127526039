<template>
	<Slider v-model="value" v-bind="slider" @update="setMutations"/>
	<div id="slider-input" class="d-flex mt-4 justify-content-between">
		<input v-model="startValue" type="text">
		<input v-model="endValue" type="text">
	</div>
</template>

<script>
import Slider from '@vueform/slider'
import {mapGetters, mapMutations} from "vuex"

export default {
  name: "CustomRangeSlider",
  components: {
    Slider
  },
  data: () => ({
    slider: {
      step: 1,
      merge: 100,
      max: 1300000,
      tooltips: false,
    },
    value: [0, 1300000],
  }),

  computed: {
    ...mapGetters(['getFilterMax',
      'getFilterMin']),
    startValue: {
      get() {
        return this.value[0]
      },
      set(value) {
        this.value[0] = +value
      }
    },
    endValue: {
      get() {
        return this.value[1]
      },
      set(value) {
        this.value[1] = (value > this.value[0])
          ? +value
          : this.value[0]
      }
    }
  },

  methods: {
    ...mapMutations(['updateFilterPriceMin', 'updateFilterPriceMax']),
    setMutations() {
      this.updateFilterPriceMin(this.value[0])
      this.updateFilterPriceMax(this.value[1])
    },
  },
  mounted() {
    this.value[0] = this.getFilterMin
    this.value[1] = this.getFilterMax
  }
}
</script>

<style lang="scss" scoped>
@import 'css/CustomRangeSlider.css';

#slider-input {
  input {
    width: 45%;
    background: #f0f2ff;
    border: 1px solid #cacee8;
    border-radius: 3px;
    text-align: center;
    padding: 5px;
    outline: none;
    color: #47285f;
  }
}

</style>