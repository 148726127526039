  import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Professions from "../views/Professions"
import PageNotFound from "../views/PageNotFound"
import Courses from "../views/Courses"
// import Blog from "../views/Blog"
// import ArticlePage from "../views/Article";
import CoursesCompare from "../views/CoursesCompare"
import Favorites from "../views/favorites"
// import Login from "../components/auth/Login"
// import Register from "../components/auth/Register"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Remotza",
      theme: "dark"
    }
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses,
    meta: {
      title: "Remotza - Курсы",
      theme: "dark"
    },
    children: [
      {
        path: '/courses/tech/:slug',
        component: Courses
      },
      {
        path: '/courses/profession/:profession_id',
        component: Courses
      },
    ]
  },
  {
    path: '/courses_compare',
    name: 'CoursesCompare',
    component: CoursesCompare,
    meta: {
      title: "Remotza - Сравнение курсов",
      theme: "dark"
    },
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: Blog,
  //   props:true,
  //   meta: {
  //     title: "Remotza - Блог",
  //     theme: "dark"
  //   },
  // },
  // {
  //   path: '/article/:id',
  //   name: 'ArticlePage',
  //   component: ArticlePage,
  //   props:true,
  //   meta: {
  //     title: "Remotza - Блог",
  //     theme: "dark"
  //   },
  // },
  {
    path: '/professions',
    name: 'Professions',
    component: Professions,
    props:true,
    meta: {
      title: "Remotza - Профессии",
      theme: "dark"
    }
  },
  {
  path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    props:true,
    meta: {
      title: "Remotza - Избранное",
      theme: "dark"
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    meta: {
      title: "404",
      theme: "dark"
    },
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login
  // },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: Register
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {el: to.hash, behavior: 'smooth'}
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {top: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
