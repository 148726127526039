<template>
	<section class="position-relative text-center profession-page-first bg-blue">
		<div class="container text-start">
			<div class="row">
				<div class="col-12 col-md-6 py-md-5 mt-md-4">
					<h2 class="text-white py-5 text-web-destinations">Ключевые профессиональные web-направления </h2>
				</div>
				<div class="col-12 col-md-6 position-relative">
					<img class="img-fluid band-correct-press mt-0 mt-md-5 me-2" src="../assets/professionpage/band_correct_press.png" alt="">
				</div>
			</div>
		</div>
	</section>
</template>

<script>
  export default {
    name: "WebDirections"
  }
</script>

<style lang="scss" scoped>

	@import "src/styles/components/media/media_web_diections";

	.profession-page-first {
		overflow: hidden;
		height: 640px;
		padding-top: 92px;

		.text-web-destinations {
			font-size: 46px;
			line-height: 1.5;
		}

	}



</style>