<template>
  <input :id="value + `-${typeFilter}`" class="d-none custom-checkbox" type="checkbox"
         :value="value"
         :checked="isChecked"
         @change="event=>onChange(event.target.value)">
  <label class="position-relative text-violet-dark text-nowrap" :for="value + `-${typeFilter}`">
    <slot/>
  </label>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    typeFilter:{
      type: String,
      default: null
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isChecked() {
      return  this.modelValue.includes(String(this.value))
    }
  },
  methods: {
    onChange(value) {
      if (this.modelValue.includes(value)) {
        this.$emit('update:modelValue', this.modelValue.filter(cv => cv !== value))
      } else {
        this.$emit('update:modelValue', this.modelValue.concat(value))
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-checkbox:not(:checked) + label:before, .custom-checkbox:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  border: 1px solid #cacee8
}

.custom-checkbox + label {
  padding-left: 35px;
}

.custom-checkbox:not(:checked) + label:after, .custom-checkbox:checked + label:after {
  content: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2017%2013%22%3E%3Cpath%20fill%3D%22%239E9EFA%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.707%209.293L15%200l1.414%201.414L5.707%2012.121%200%206.414%201.414%205l4.293%204.293z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
  position: absolute;
  top: .15em;
  left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  transition: all .2s;
  width: 1rem;
}

.custom-checkbox:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
</style>