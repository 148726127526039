const faq = [
    {
        question: "С чего мне начать?",
        response: "В первую очередь необходимо определиться с направлением. Мы подготовили небольшой тест на профориентацию, который поможет Вам. Вы также можете почитать про основные web-направления на нашей <a href='javascript:window.location.href = `/professions`'>специальной странице.</a>"
    },
    {
        question: "Я определился с направлением разработки.",
        response: "Вы можете посмотреть на популярность языков программирования. По исследованиям 2020 года рейтинг выглядит следующим образом: JavaScript, HTML/CSS, SQL, Python, Java, C#, PHP, TypeScript, C++, C, Go. Вы также можете зайти на HeadHunter и посмотреть количество вакансий по тому или иному языку программирования - чем вакансий больше, чем проще будет найти работу."
    },
    {
        question: "Можно ли освоить профессию бесплатно?",
        response: "Да. Для начала пройдите бесплатные курсы, чтобы понять суть профессии. Далее Вам необходимо будет самостоятельно составить план обучения и искать материалы в сети. \n" +
            "Такой вариант сложнее, чем получить первоначальную базу знаний с полноценного курса, но вполне имеет место быть."
    },
    {
        question: "Сколько времени нужно на освоение профессии?",
        response: "Все зависит от упорства, объема практики и первоначальной подготовки - кто-то сможет получить свой первый оффер уже через 4 месяца, а кому-то потребуется больше года, чтобы почуствовать себя уверенным Junior'ом."
    },
    {
        question: "Нужно ли знать английский?",
        response: "Для освоения профессии знать английский вовсе необязательно, но Вам будет проще, если у Вас есть хоть какая-то база на уровне pre-intermediate."
    },
    {
        question: "Как выбрать курсы?",
        response: "На нашей <a href='javascript:window.location.href = `/courses`'> отдельной странице</a>  собрано более 700 курсов от ведущих школ. Воспользуйтесь поиском и/или фильтрацией, чтобы быстрее найти то, что Вам интересно."
    },
    {
        question: "Смогу ли я работать сразу после прохождения курса?",
        response: "В 98% случаев - нет. Курсы в большинстве своем дают лишь базовые знания - необходимо прилагать немало усилий во внеурочное время, чтобы дотянуть свой уровень хотя бы до Junior'а. \n" +
            "Именно поэтому следующим этапом в Вашем образовательном процессе будет ментор.",
    },

    {
        question: "Кто такой ментор? ",
        response: "Ментор - это тот человек, который сможет провести аудит Ваших знаний, выявить слабые места и написать программу дополнительной подготовки. \n" +
            "Как правило это человек с приличным опытом работы в нужной Вам сфере, который знает на что необходимо обратить внимание."
    },
    {
        question: "Могу ли я не проходить курс и сразу пойти к ментору?",
        response: "Да, но рекомендуем так делать, только если у Вас уже есть неплохая база и вы хотели бы систематизировать знания и восполнить пробелы. \n" +
            "В противном случае процесс будет длительным - это как нанять репетитора по китайскому языку и учить его с нуля."
    },
    {
        question: "Как подготовиться к собеседованию?",
        response: "Подготовку к собеседовнию осуществляет ментор - он подскажет на какие моменты обращает внимание работодатель и что необходимо знать для успешного прохождения интервью на должность Junior."
    },
    {
        question: "Как правильно составить резюме?",
        response: "Систематизируйте свои знания, подробно опишите с чем был практический опыт работы и ставьте соответсвующие зарплатные ожидания вашему опыту."
    },
    {
        question: "Я не проходил курсы, но хочу работать",
        response: "Если у Вас есть хорошая база, рекомендуем систематизировать знания вместе с ментором и воспользоваться нашим сервисом помощи в трудоустройстве. В противном случае рекомендуем пройти необходимый курс."
    },
    {
        question: "Вы поможете найти работу?",
        response: "Да, но только после того как будет пройден этап подготовки с ментором и будет получена от него рекомендация."
    },
    {
        question: "У меня есть вопрос, на который я не нашел ответ?",
        response: "Отправьте свой вопрос к нам на почту <a href='mailto:hello@remotza.com'> hello@remotza.com</a> и мы обязательно Вам ответим."
    },
];

export default {
    faq: faq
};