export default {
    mutations: {
        addCoursesCompareId(store, id) {
            let coursesCompareIds = [];

            if (localStorage.getItem('coursesCompareIds')) {
                coursesCompareIds = JSON.parse(localStorage.getItem('coursesCompareIds'))
            }

            if (coursesCompareIds.indexOf(id) === -1) {
                coursesCompareIds = coursesCompareIds.concat(id)
            }

            localStorage.setItem('coursesCompareIds', JSON.stringify(coursesCompareIds))
        },
        removeCoursesCompareId(store, id) {
            let coursesCompareIds = [];

            if (localStorage.getItem('coursesCompareIds')) {
                coursesCompareIds = JSON.parse(localStorage.getItem('coursesCompareIds'))
            }


            if (coursesCompareIds.indexOf(id) !== -1) {
                 coursesCompareIds.splice(coursesCompareIds.indexOf(id), 1)
            }
            localStorage.setItem('coursesCompareIds', JSON.stringify(coursesCompareIds))
        },
        updateCompareLoadingStatus(state, newLoadingStatus) {
            state.compareLoadingStatus = newLoadingStatus
        }
    },
    state: {
        coursesCompareIds: [],
        compareLoadingStatus: true,
    },
    getters: {
        getCompareLoadingStatus(state) {
            return state.compareLoadingStatus
        },
        getCoursesCompareIds() {
            if (JSON.parse(localStorage.getItem('coursesCompareIds'))) {
                return JSON.parse(localStorage.getItem('coursesCompareIds'))
            } else {
                return []
            }
        },
        getCoursesCompareCount() {
            if (localStorage.getItem('coursesCompareIds')) {
                return JSON.parse(localStorage.getItem('coursesCompareIds')).length
            } else {
                return null
            }
        }
    }
}