<template>
	<section id="jun-mid-sen-ment" class="bg-violet-blue position-relative pattern-dots">
		<div class="container pt-5 pb-5">
			<h3 class="pb-5 pt-2">Усредненные общепринятые обозначения уровня квалификации</h3>
			<div class="d-flex flex-wrap">
				<div class="col-lg-3 col-md-6 col-12 border border-royal-blue block-lvl py-4 px-4">
					<span class="fs-8">Начальный уровень</span>
					<p class="mt-3 text-white-50">от 0.5 до 2 лет</p>
					<div class="d-flex img-rank mt-4">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 8" height="22" width="60" class="mt-auto">
							<path fill="#FC0" fill-rule="evenodd" d="M11 4L0 0v4l11 4 11-4V0L11 4z" clip-rule="evenodd"></path>
						</svg>
					</div>
					<span class="d-block rank py-3">Junior</span><p class="text-white-50">Решение стандартных задач с минимальным риском</p><p class="text-white-50">Базовое знание предметной области</p>
				</div>
				<div class="col-lg-3 col-md-6 col-12 border border-royal-blue block-lvl py-4 px-4">
					<span class="fs-8">Средний уровень</span><p class="mt-3 text-white-50">от 2 до 4 лет</p>
					<div class="d-flex img-rank mt-4">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 14" height="39" width="60" class="mt-auto">
							<path fill="#FC0" d="M11 10L0 6v4l11 4 11-4V6l-11 4z"></path>
							<path fill="#FC0" d="M11 4L0 0v4l11 4 11-4V0L11 4z"></path>
						</svg>
					</div>
					<span class="d-block rank py-3">Middle</span><p class="text-white-50">Самостоятельное решение задач, выполнение проектов средней сложности</p><p class="text-white-50">Участие в проектировании решений</p><p class="text-white-50">Достаточное знание предметной области</p>
				</div>
				<div class="col-lg-3 col-md-6 col-12 border border-royal-blue block-lvl py-4 px-4">
					<span class="fs-8">Высокий уровень</span><p class="mt-3 text-white-50">свыше 4 лет</p>
					<div class="d-flex img-rank mt-4">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 20" height="55" width="60" class="mt-auto">
							<path fill="#FC0" d="M11 16L0 12v4l11 4 11-4v-4l-11 4z"></path>
							<path fill="#FC0" d="M11 10L0 6v4l11 4 11-4V6l-11 4z"></path>
							<path fill="#FC0" d="M11 4L0 0v4l11 4 11-4V0L11 4z"></path>
						</svg>
					</div>
					<span class="d-block rank py-3">Senior</span><p class="text-white-50">Комплексный контроль и мониторинг программных решений</p><p class="text-white-50">Проектирование решений</p><p class="text-white-50">Экспертное знание предметной области</p>
				</div>
				<div class="col-lg-3 col-md-6 col-12 border border-royal-blue block-lvl py-4 px-4">
					<span class="fs-8">Мастерский уровень</span><p class="mt-3 text-white-50">свыше 10 лет</p>
					<div class="d-flex img-rank mt-4">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 35" height="95" width="60" class="mt-auto">
							<path fill="#FC0" d="M11 31L0 27v4l11 4 11-4v-4l-11 4z"></path>
							<path fill="#FC0" d="M11 25L0 21v4l11 4 11-4v-4l-11 4z"></path>
							<path fill="#FC0" d="M11 19L0 15v4l11 4 11-4v-4l-11 4z"></path>
							<path fill="#FC0" fill-rule="evenodd" d="M7.426 8.678l-.844 4.92L11 11.275l4.418 2.323-.843-4.92 3.574-3.484-4.94-.718L11 0 8.79 4.476l-4.939.718 3.575 3.484zm5.336 1.264L11 9.016l-1.762.926.337-1.962-1.426-1.39 1.97-.286L11 4.52l.881 1.785 1.97.286-1.425 1.39.336 1.962z" clip-rule="evenodd"></path>
						</svg>
					</div>
					<span class="d-block rank py-3">Mentor</span><p class="text-white-50">Комплексный контроль и мониторинг программных решений</p><p class="text-white-50">Проектирование решений</p><p class="text-white-50">Экспертное знание предметной области</p><p class="text-white-50" id="check_skills">Многолетний опыт</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
  export default {
    name: "JunMidSenMent"
  }
</script>

<style lang="scss" scoped>

	@import "src/styles/components/media/media_junmid.scss";


	#jun-mid-sen-ment {
		.img-rank {
			height: 95px;
		}

		.rank {
			font-size: 36px;
			line-height: 1.35;
			font-weight: 500;
			letter-spacing: 0.5px;
		}

		h3 {
			//color: var(--remotza-yellow);
			font-size: 1.625rem;
			line-height: 1.55;
			font-weight: 400;
		}
	}


</style>