<!--suppress JSValidateTypes -->
<template>
  <div class="wrap_course_filter me-1" v-if="!getLoadingStatus">
    <div class="d-flex" v-if="getWindowWidth <= 1024">
      <button @click="isShow = !isShow" class="btn btn-link btn-link-course-primary mx-auto my-3">
        <span v-if="!isShow">Развернуть возможность фильтрации</span>
        <span v-else>Свернуть фильтры</span>
      </button>
    </div>
    <div class="container mt-3 sticky-cont scroll-bar" v-if="isShow">
      <div v-if="isShow">
        <form  v-cloak ref=filers
              class="flex-column flex-sm-row flex-wrap justify-content-center column row-cols-1 row-cols-md-auto">
          <div class="tech d-flex flex-column">
            <span class="mb-3 text-gray-pale">Технология:</span>
            <div class="mb-2 text-violet-dark">
              <Multiselect
                  v-model="proglang_id"
                  placeholder="Выберите технологию"
                  noOptionsText="Ничего не найдено"
                  noResultsText="Ничего не найдено"
                  label="name"
                  trackBy="name"
                  :options="allProglangs"
                  :searchable="true"
                  :canClear="true"
                  class="multiselect-filter"
              />
            </div>
          </div>
          <div>
            <span class="mb-3 text-gray-pale">Категория:</span>
            <div style='max-height: 300px; overflow-x: hidden' :class="{'d-flex flex-column mt-3 pe-5 overflow-hidden': !isShowFilterProfessions, 'scroll-bar d-flex flex-column mt-3 pe-5': isShowFilterProfessions}">
              <div v-for="profession in allProfessions" :key="profession.id" ref="item" class="align-items-center mb-2">
                <CustomCheckbox typeFilter="profession" :isChecked="getFilterProfessions.includes('' + profession.id)"
                                :name="'profession'"
                                :value=profession.id
                                v-model="profession_id"
                >{{ profession.name }}</CustomCheckbox>
              </div>
            </div>
          </div>
          <p v-if="allProfessions.length > 9" @click="isShowFilterProfessions = !isShowFilterProfessions" style="color: black ">{{isShowFilterProfessions ? 'Скрыть' : 'Показать все'}}</p>
          <div>
            <span class="mb-3 text-gray-pale">Школа:</span>
            <div style='max-height: 300px;overflow-x: hidden' :class="{'d-flex flex-column mt-3 pe-5 overflow-hidden': !isShowFilterSchool, '  d-flex flex-column mt-3 pe-5': isShowFilterSchool}">
              <div v-for="school in allPartners" :key="school.id" class="align-items-center mb-2">
                <CustomCheckbox typeFilter="school" :isChecked="getFilterSchool.includes('' + school.id)" :value=school.id
                                :name="'school'"
                                v-model="school_id"
                >{{ school.name }}</CustomCheckbox>
              </div>
            </div>
          </div>
          <p v-if="allPartners.length > 9" @click="isShowFilterSchool = !isShowFilterSchool" style="color: black ">{{isShowFilterSchool ? 'Скрыть' : 'Показать все'}}</p>
          <div class="d-flex flex-column pe-5">
            <span class="mb-3 text-gray-pale">Уровень:</span>
            <div class="mb-2">
              <CustomCheckbox :name="'level'" :isChecked="getFilterLevel.includes('0')" :value="0" v-model="level_id">Начальный</CustomCheckbox>
            </div>
            <div class="mb-2">
              <CustomCheckbox :name="'level'" :isChecked="getFilterLevel.includes('1')" :value="1" v-model="level_id">Продвинутый</CustomCheckbox>
            </div>
          </div>
          <div class="d-flex flex-column pe-5">
            <span class="mb-3 text-gray-pale">Длительность:</span>

            <div class="mb-2">
              <CustomCheckbox :isChecked="getFilterTime.includes('2592000')" :value="2592000" v-model="filter_time_id">до месяца</CustomCheckbox>
            </div>
            <div class="mb-2">
              <CustomCheckbox :isChecked="getFilterTime.includes('15552000')" :value="15552000" v-model="filter_time_id">до полугода</CustomCheckbox>
            </div>
            <div class="mb-2">
              <CustomCheckbox :isChecked="getFilterTime.includes('31104000')" :value="31104000" v-model="filter_time_id">до 1 года</CustomCheckbox>
            </div>
            <div class="mb-2">
              <CustomCheckbox :isChecked="getFilterTime.includes('31104001')" :value="31104001" v-model="filter_time_id">больше года</CustomCheckbox>
            </div>
          </div>
          <div class="d-flex flex-column px-3">
            <span class="mb-3 text-gray-pale">Стоимость:</span>
            <CustomRangeSlider />
            <div class="d-flex mt-3 justify-content-between">
              <button @click.prevent="onSubmit" class="btn btn-blue w45">Поиск</button>
              <button @click.prevent="clearFilter" class="btn btn-blue w45">Сбросить</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '../ui/CustomCheckbox'
import CustomRangeSlider from '../ui/CustomRangeSlider'
import Multiselect from '@vueform/multiselect'
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex'

export default {
  name: "CourseFilter",
  components: {
    CustomRangeSlider,
    CustomCheckbox,
    Multiselect,
  },
  computed: {
    ...mapGetters(
        [
          'allProglangs',
          'getProglangIdBySlugName',
          'getFilterParams',
          'allPartners',
          'allProfessions',
          'getLoadingStatus',
          'getFilterProglang',
          'getFilterSchool',
          'getFilterLevel',
          'getFilterTime',
          'getFilterProfessions',
          'getFilterMin',
          'getFilterMax',
          'getPage',
          'getLimit',
          'getWindowWidth',
          'getFilterPattern'
        ]
    ),
    proglang_id: {
      get() {
        return this.getFilterProglang
      },
      set(id) {
        this.updateFilterProglang(id)
      }
    },
    profession_id: {
      get() {
        return this.getFilterProfessions
      },
      set(id) {
        this.updateFilterProfessions(id)
      }
    },
    school_id: {
      get() {
        return this.getFilterSchool
      },
      set(id) {
        this.updateFilterSchools(id)
      }
    },
    level_id: {
      get() {
        return this.getFilterLevel
      },
      set(id) {
        this.updateFilterLevel(id)
      }
    },
    filter_time_id: {
      get() {
        return this.getFilterTime
      },
      set(id) {
        this.updateFilterTime(id)
      }
    },
    filter_min: {
      get() {
        return this.getFilterMin
      },
      set(id) {
        this.updateFilterPriceMin(id)
      }
    },
    filter_max: {
      get() {
        return this.getFilterMax
      },
      set(id) {
        this.updateFilterPriceMax(id)
      }
    },
    page: {
      get() {
        return this.getPage
      },
      set(id) {
        return this.updatePage(id)
      }
    },
  },

  data() {
    return {
      isShow: false,
      isShowFilterSchool: false,
      isShowFilterProfessions: false,
    }
  },
  created() {
    //проверка размера экрана для фильтров
    if(this.getWindowWidth > 768) this.isShow = true
    //если партнеров нет в сторе, то грузим. Выселять не надо
    if (Object.keys(this.allPartners).length === 0) {
      this.fetchPartners()
    }
    this.fetchProfessions()
    this.fetchProglangs()
  },
  mounted() {
    // TODO выселить отседа
    if (this.$route.params.slug) {
      let slug = this.$route.params.slug.toLowerCase()
      this.proglang_id = this.getProglangIdBySlugName(slug) ?? null
      this.updateFilterProglang(this.proglang_id)
      this.isShow = true
    }
    this.emitter.on('submitFilter', () => this.onSubmit())
  },
  methods: {
    ...
        mapMutations(
            [
              'updateFilterProfessions',
              'clearFilterProfessions',
              'updateFilterSchools',
              'updateFilterLevel',
              'updateFilterTime',
              'clearPage',
              'updateFilterPriceMin',
              'updateFilterPriceMax',
              'updateFilterProglang',
              'allClearFilter',
              'updateLoadingStatus',
              'updateProglangsSlug',
              'updateProglangs'
            ]
        ),
    ...mapActions([
          'fetchPartners',
          'fetchProglangs',
          'fetchProfessions'
        ]),
    clearFilter() {
      this.$router.replace({})
      this.proglang_id = null
      this.filter_professions = []
      this.filter_school = []
      this.filter_time = []
      this.filter_level = []
      this.clearPage()
      this.updateFilterPriceMin(0)
      this.updateFilterPriceMax(1300000)
      this.allClearFilter()
      this.$store.dispatch('applyFilter')
      this.updateLoadingStatus(true)
    },
    onSubmit() {
      this.clearPage()
      let filterPrepare = this.getFilterParams

      this.$router.push({
        query: {
          filter_proglang: filterPrepare.filter_proglang ? filterPrepare.filter_proglang : [],
          filter_level: filterPrepare.filter_level,
          filter_professions: filterPrepare.filter_professions ? filterPrepare.filter_professions : [],
          filter_school: filterPrepare.filter_school ? filterPrepare.filter_school : [],
          filter_time: filterPrepare.filter_time,
          filter_min: filterPrepare.filter_min,
          filter_max: filterPrepare.filter_max,
          // page: filterPrepare.page == 1 ? [] : filterPrepare.page,
          limit: filterPrepare.limit == 16 ? [] : filterPrepare.limit,
          sort: filterPrepare.sort ? filterPrepare.sort : [],
          pattern: this.getFilterPattern
        }
      })
      this.updateLoadingStatus(true)
      this.$store.dispatch('applyFilter')
    }
  },
  // watch: {
  //   page() {
  //     this.onSubmit()
  //   },
  //   limit() {
  //     this.onSubmit()
  //   }
  // },
  beforeUnmount() {
    this.updateLoadingStatus(true);
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.tech {
  width: 240px;
}

.multiselect-filter {
  --ms-bg: #f0f2ff;
  --ms-dropdown-bg: #f0f2ff;
  --ms-placeholder-color: #898397;
  --ms-option-color-pointed: white;
  --ms-option-color-selected: white;
  --ms-option-bg-pointed: var(--lilac-light);
  --ms-option-bg-selected: var(--lilac-light);
  --ms-option-bg-selected-pointed: var(--lilac-light);
  --ms-ring-width: 1px;
  --ms-ring-color: var(--remotza-violet-dark);
}

.wrap_course_filter {
  @media (min-width: 1024px) {
    width: 300px;
  }
}

.sticky-cont {
  position: sticky;
  top: 120px;
  height: 86vh;
  overflow-y: auto;
  width: 300px;
  overflow-x: hidden;
  margin-bottom: 50px;
  @media (max-width: 1440px) {
    top: 100px;
    height: 72vh;
    margin-bottom: 42px;
  }
}

.scroll-bar::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

.scroll-bar::-webkit-scrollbar-track {
  background: #dadada;        /* color of the tracking area */
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #949494;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
</style>