<template>
	<WebDirections></WebDirections>
	<ProfessionTabs></ProfessionTabs>
	<JunMidSenMent></JunMidSenMent>
<!--	<Cubes></Cubes>-->
</template>


<script>
  import WebDirections from "@/components/WebDirections";
  import ProfessionTabs from "@/components/ProfessionTabs";
  import JunMidSenMent from "@/components/JunMidSenMent";
  // import Cubes from "@/components/Cubes"
  export default {
    name: "Professions",
    components: {
      WebDirections,
      ProfessionTabs,
      // Cubes,
      JunMidSenMent
    }
  }
</script>
