<template>
  <section class="chan">
    <div class="container wrap-chan-content position-relative">
      <div class="row">
        <div class="col-md-6">
          <div class="chan-gif d-none d-md-block position-relative">
            <video loop="" muted="" autoplay="" playsinline="" class="img-fluid">
              <source src="../assets/webm/kungfu_coder_8bit.webm" type="video/webm">
            </video>
          </div>
        </div>
        <div class="col-md-6 text-violet-dark text-center">
          <h2 class="text-center text-violet-dark">
            404
          </h2>
          <h2>
            Страница не найдена
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style lang="scss" scoped>

.chan {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(218, 221, 240, 1)), to(rgba(247, 240, 255, 1)));
  background-image: -webkit-linear-gradient(top, rgba(218, 221, 240, 1), rgba(247, 240, 255, 1));
  background-image: linear-gradient(to bottom, rgba(218, 221, 240, 1), rgba(247, 240, 255, 1));
  overflow: hidden;
  height: 780px;

  .row{
    margin-top: 112px;
  }
  .wrap-chan-content {
    padding-top: 109px;
    padding-bottom: 109px;

    h1{
      font-size: calc(3rem + 2.7vw);
    }

    &:before {
      content: '';
      background-image: url(../assets/bg/solarsystem_vector.png);
      background-size: 100% 100%;
      height: 960px;
      width: 960px;
      position: absolute;
      left: -170px;
      top: -70px;
    }

    .chan-gif {
      left: 76px;
      height: 384px;
      width: 384px;
      background-repeat: no-repeat;
    }
  }
}

.dots-decor-w {
  height: 238px;
}
</style>