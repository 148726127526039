<template>
	<div class="nav-tab-content">
		<div class=" position-relative" id="nav-full">
			<img src="../../assets/professionpage/Fullstack_character_.png" alt="" class="position-absolute d-none d-sm-block">
			<div class="row pb-4">
				<div class="col-12 col-md-6 z-index-1">
					<h3 class="pb-4">Фуллстек разработчик</h3>
					<a @click="goFilter()" class="btn btn-outline-blue-course me-md-3 mb-2 mb-md-0">Выбрать курс фулстек программирования →</a>
				</div>
				<div class="col-12 col-md-6 z-index-1">Fullstack-программист - человек, умеющий одновременно работать с front-end и back-end, составляющими сайта. Это - универсальный специалист, который может полностью создать сайт, самостоятельно настроив его визуальную и функциональную части.</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/skills_icon.png" alt="">
					<span class="ms-3 text-for-img">cамые необходимые навыки</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">JavaScript | HTML | CSS | PHP | Node.js</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/demand_icon.png" alt="">
					<span class="ms-3 text-for-img">на что растёт спрос</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">Vue.js | .NET Framework | Redis</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/cash_icon.png" alt="">
					<span class="ms-3 text-for-img">медиана заработных плат</span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">115 000 ₽ — все
					<div>63 200 ₽ — новички</div>
				</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/need_icon.png" alt="">
          <span class="ms-3 text-for-img">востребованность
            <span class="mt-2 d-block bar-chart-descr">число таких вакансий на тысячу</span>
          </span>
				</div>
				<div class="col-12 col-md-6 mt-3 prof-descr fs-8">
					<BarChart v-bind:barChartData="barChartData"/>
				</div>
			</div>
			<div class="row border-top pb-3 position-relative">
				<div class="col-12 col-md-6 ps-0">
					<img class="img-fluid" src="../../assets/professionpage/expirience_icon.png" alt="">
					<span class="ms-3 text-for-img">требуемый опыт</span>
				</div>
				<div class="col-12 col-md-6 mt-3 fs-8">
					<span class="prof-descr">
						<DoughnutChart v-bind:doughnutChartData="doughnutChartData"/>
					</span>
				</div>
			</div>
			<div class="row">
				<div class="col info">
					По данным Яндекс и Headhunter, 2016 – 2023
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {defineComponent} from 'vue'
  import BarChart
    from "../charts/BarChart";
  import DoughnutChart
    from "../charts/DoughnutChart";
  import {mapMutations} from "vuex";

  export default defineComponent({
    name: "TabFullstack",
    components: {
      DoughnutChart,
      BarChart
    },
    data() {
      return {
        barChartData: {
          labels: [2020, 2021, 2022],
          datasets: [
            {
              backgroundColor: "#babae8",
              data: [0.10, 0.16, 0.31]
            }
          ]
        },
        doughnutChartData: {
          labels: ["Больше 6 лет", "От 4 до 6 лет", "От 1 до 3 лет", "До года"],
          datasets: [
            {
              backgroundColor: ["#520abf", "#9f4d74", "#ffbd23", "#e5869d"],
              data: [8, 25, 50, 17],
              borderWidth: 1
            }
          ]
        }
      }
    },
    methods : {
      ...mapMutations(
          [
            'updateLoadingStatus',
            'updateFilterProfessions',
            'clearFilterProfessions',
          ]
      ),
      goFilter(){
        this.updateLoadingStatus(true)

        this.$router.push({ path: '/courses', query: {filter_proglang: 17}})
      }
    }
  })
</script>

<style lang="scss" scoped>

	#nav-full > img {
		left: 40%;
		top: 10%;
		transform: translateX(-40%)
	}

	.info {
		color: var(--dark-grey);
		font-size: 12px;
	}

</style>