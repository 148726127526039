import axios from 'axios'
import {
    API_PROMO
} from "@/common/config"

export default {
    actions: {
        fetchPromo(context) {
            axios.get(API_PROMO).then((response) => {
                const promo = response.data
                context.commit('updatePromo', promo)
            })
        }
    },
    mutations: {
        updatePromo(state, promo) {
            state.promo = promo
        },
    },
    state: {
        promo: [],
    },
    getters: {
        getAllPromo(state) {
            return state.promo
        },
        getCountPromo(state) {
            return state.promo.length
        },
        promoFindById: state => id => {
            return state.promo.find(promo => promo.id === id);
        }
    }
}