<template>
  <div v-cloak class="block-partners">
    <div class="container">
      <h2 class="mb-4">Наши партнёры</h2>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3 d-flex mb-0 mb-lg-4" v-for="partner in allPartners" :key="partner.id">
          <a :href="partner.url" class="block_course">
            <img :src="partner.pic"
                 :alt="partner.name"
                 class="m-auto"
                 loading="lazy">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
  name: "Partners",
  computed: mapGetters(['allPartners']),
  methods: mapActions(['fetchPartners']),
  mounted() {
    this.fetchPartners()
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}

.block-partners {
  background: var(--remotza-slate-blue);
  padding: 45px 0 50px 0;

  h2 {
    color: var(--remotza-cyan);
    font-size: 32px;
    line-height: 35px;
    font-weight: 500;
  }

  .block_course {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    background: #3A3AC1;
    border-radius: 5px;

    img {
      width: 200px;
    }
  }
}

@media (max-width: 576px) {
  .block-partners {
    h3 {
      font-size: 24px;
    }

    padding: 25px 0;
  }
}

@media (max-width: 1199.98px) {
  .block-partners {
    .block_course {
      margin: 15px 0;
    }
  }
}
</style>