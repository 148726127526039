<template>
  <div class="card">
    <div class="card-body">
      <h3 class="card-title">{{ header }}</h3>
      <p class="card-text mb-5">
        <slot name="content"></slot>
      </p>
      <router-link :to="{ path: path, hash: hash }">
        <button type="button" class="btn btn-blue w-100">
          <slot name="button"></slot>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DottedCard",
  props: {
    header: {
      type: String,
      default: 'Change "header" props, plz'
    },
    path: {
      type: String,
      default: '/'
    },
    hash: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
  height: 100%;
  position: relative;
  color: var(--deep-gray);
  box-shadow: 4px 4px 18px -2px rgba(34, 60, 80, 0.3);

  .card-title {
    font-size: 22px;
  }

  .card-text {
    font-size: 14px;
  }

  a {
    bottom: 15px;
    display: block;
    position: absolute;
    right: 15px;
    left: 15px;
  }
}

@media (min-width: 1023px) and (max-width: 1440px) {
  .card {
    .card-title {
      font-size: 20px;
    }
  }
}
</style>