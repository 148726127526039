<template>
  <header>
    <nav id="main_nav" class="navbar navbar-expand-lg naw-wrap fixed-top p-0" :class="theme">
      <div class="container">
        <div id="logo" class="overflow-hidden ms-3">
          <router-link to="/" class="navbar-brand d-block position-relative" id="first-logo">
            <img width="160" height="75" :src="getSecondLogo" alt="logo" loading="lazy" class="pe-3">
          </router-link>
          <!--          <router-link class="navbar-brand d-block position-relative" id="second-logo" to="/">-->
          <!--            <img width="160" height="40" :src="getSecondLogo" alt="logo" loading="lazy" class="pe-3">-->
          <!--          </router-link>-->
        </div>
        <button @click="clickBurger" class="navbar-toggler" :class="{collapsed: show.collapsed}" type="button"
                data-bs-toggle="collapse" data-bs-target="#nav-collapse-main" aria-controls="nav-collapse-main"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{show: show.list}" id="nav-collapse-main">
          <ul class="ms-lg-5 navbar-nav pt-3 pt-lg-0">
            <li v-for="item in menu" :key="item.text" class="d-flex h-100 position-relative px-lg-2 nav-item">
              <router-link class="align-self-center stretched-link nav-link" :to="{ path: item.link, hash: item.hash }">
                {{ item.text }}
                <div class="counter" v-if="item.counter > 0">{{ item.counter }}</div>
              </router-link>
            </li>
            <!--            <li>-->
            <!--              <a href="" v-on:click="clickLogout" class="align-self-center stretched-link nav-link">logout</a>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import authService from "../services/auth.service";
import {mapGetters} from "vuex";

export default {
  name: "Header",
  data() {
    return {
      menu: [
        {text: 'Главная', link: '/', hash: ''},
        {text: 'Курсы', link: '/courses', hash: ''},
        {text: 'Профессии', link: '/professions', hash: ''},
        // {text: 'Блог', link: '/blog', hash: ''},
        {text: 'Сравнение курсов', link: '/courses_compare', hash: '', counter: 0},
        {text: 'Избранное', link: '/favorites', hash: '', counter: 0},
      ],
      bg: {
        backgroundColor: '#4300a8',
        transition: 'background-color 0.5s ease 0s',
      },
      themes: {
        dark: {
          style: 'navbar-dark',
          secondLogoPath: 'logo_white.svg',
          backgroundColor: '#4300a8',
        },
        light: {
          style: 'navbar-light border-white',
          secondLogoPath: 'logo_header2.png',
          backgroundColor: '#f0f2ff'
        }
      },
      show: {
        list: false,
        collapsed: true,
        ariaExpanded: false,
      }
    }
  },
  computed: {
    ...mapGetters(['getCoursesCompareCount']),
    activeTheme() {
      let theme = this.themes.dark
      if (this.$route.meta.theme === 'light') {
        theme = this.themes.light
      }
      return theme
    },
    theme() {
      return this.activeTheme.style
    },
    getSecondLogo() {
      return require('../assets/logo/' + this.activeTheme.secondLogoPath)
    },
  },
  // beforeMount() {
  //   window.addEventListener('scroll', this.setHeader, {passive: true})
  // },
  // beforeUnmount() {
  //   window.removeEventListener('scroll', this.setHeader);
  // },
  methods: {
    setHeader() {
      this.bg.backgroundColor = window.pageYOffset > 0 ? this.activeTheme.backgroundColor : 'transparent'

      let firstLogo = document.getElementById('first-logo'),
          secondLogo = document.getElementById('second-logo')

      firstLogo.style.top = '-' + window.pageYOffset * 0.3 + 'px'
      if (window.pageYOffset > 180) {
        secondLogo.style.top = '-55px'
      } else {
        secondLogo.style.top = '-' + window.pageYOffset * 0.3 + 'px'
      }
    },
    clickBurger() {
      this.show.list = !this.show.list;

      // this.show.bgColor = !this.show.bgColor
      // if (this.show.bgColor) {
      //   this.bg.backgroundColor = 'rgb(67, 0, 168)';
      // } else {
      //   this.bg.backgroundColor = 'transparent';
      // }

      this.show.collapsed = !this.show.collapsed;
      this.show.ariaExpanded = !this.show.ariaExpanded;
    },
    clickLogout() {
      authService.logout();
    }
  },
  mounted() {
    this.menu[3].counter = this.getCoursesCompareCount
    this.emitter.on("compareCount", () => {
      this.menu[3].counter = JSON.parse(localStorage.getItem('coursesCompareIds')).length
    });

    if (localStorage.getItem('coursesFavouriteIds')) {
      this.menu[4].counter = JSON.parse(localStorage.getItem('coursesFavouriteIds')).length
    }
    this.emitter.on("FavoritesCount", () => {
      this.menu[4].counter = JSON.parse(localStorage.getItem('coursesFavouriteIds')).length
    });
  }
}
</script>

<style lang="scss" scoped>
#main_nav {
  background-color: rgb(56, 56, 198);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  padding-left: 20px;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("../assets/svg/burger.svg");
}

@media (min-width: 992px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 8px;
  }

  .naw-wrap .navbar-nav .nav-item > a:not(.rounded):not(#user-avatar)::after {
    content: '';
    transition: opacity 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 67%;
    opacity: 0;
  }

  .naw-wrap .navbar-nav .nav-item:hover > a:not(.rounded):not(#user-avatar)::after {
    opacity: 1;
  }

  .naw-wrap.navbar-dark .navbar-nav .nav-item > a:not(.rounded):not(#user-avatar)::after {
    border-bottom: solid var(--remotza-yellow) 3px;
  }

  .naw-wrap.navbar-light .navbar-nav .nav-item > a:not(.rounded):not(#user-avatar)::after {
    border-bottom: solid rgba(255, 255, 255, 0.2) 3px;
  }

  #nav-collapse-main {
    height: 76px;
  }

  #nav-collapse-main > ul:first-child {
    height: 100%;
  }
}

.counter {
  position: absolute;
  top: 18px;
  right: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 22px;
  height: 22px;
  border-radius: 1.1rem;
  color: var(--remotza-violet-blue);
  border: 2px solid var(--remotza-violet-blue);
  background-color: #FFFFFF;
  font-weight: 500;
  line-height: 18px;
  font-size: var(--font-size);
  z-index: 1;
}

@media (max-width: 770px) {
  .counter {
    left: 165px;
    right: 0;
    top: 10px;
  }
}
</style>