<template>
  <Header/>
  <router-view/>
  <notifications
      position="bottom right"
      width="30%"
  />
  <Partners/>
  <ScrollButton/>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Partners from "./components/Partners";
import ScrollButton from "./components/ScrollButton";
export default {
  components: {
    Partners,
    Footer,
    Header,
    ScrollButton
  }
}
</script>

<style>
body::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #dadada;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #949494;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

</style>